import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconClose from '../IconClose/IconClose';

import css from './Chip.module.css';
import Button from '../Button/Button';

const Chip = props => {
  const { className, content, onRemove } = props;
  const classes = classNames(css.root, className || css.rootClassName);
  const handleRemove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove();
  }
  return (
    <div className={classes}>
      <p className={css.content}>{content}</p>
      <Button type="button" rootClassName={css.closeButton} onClick={handleRemove}>
        <IconClose rootClassName={css.closeIcon}/>
      </Button>
    </div>
  );
};

Chip.defaultProps = {
  className: null,
  content: null,
  onRemove: () => {},
};

const { string, func } = PropTypes;

Chip.propTypes = {
  className: string,
  content: string,
  onRemove: func,
};

export default Chip;
