/**
 * Transaction process graph for product orders:
 *   - default-purchase
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes an order for a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // Provider can mark the product shipped/delivered
  MARK_DELIVERED: 'transition/mark-delivered',

  // Novica can mark the product shipped/delivered
  MARK_DELIVERED_BY_ADMIN: 'transition/mark-delivered-by-admin',

  // Operator or the provider can cancel the purchase before product has been marked as delivered
  CANCEL: 'transition/cancel',
  CANCEL_BY_ADMIN: 'transition/cancel-by-admin',

  // Reviews are given through transaction transitions. Only the buyer can review the product.
  REVIEW_BY_CUSTOMER: 'transition/review-by-customer',

  // Transitions for creating a sub transaction for shopping cart checkout
  // Since Flex send review via transition's action so we need to create a sub transaction for cart transaction
  MAKE_A_REVIEW_FOR_CART_ITEM: 'transition/make-a-review-for-cart-item',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

export const states = {
  INITIAL: 'initial',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PURCHASED: 'purchased',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  REVIEWED: 'reviewed',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-purchase/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.MAKE_A_REVIEW_FOR_CART_ITEM]: states.REVIEWED,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PURCHASED]: {
      on: {
        [transitions.MARK_DELIVERED]: states.DELIVERED,
        [transitions.MARK_DELIVERED_BY_ADMIN]: states.DELIVERED,
        [transitions.CANCEL_BY_ADMIN]: states.CANCELED,
        [transitions.CANCEL]: states.CANCELED,
      },
    },

    [states.CANCELED]: {},

    [states.DELIVERED]: {
      on: {
        [transitions.REVIEW_BY_CUSTOMER]: states.REVIEWED,
      },
    },

    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PAYMENT,
    transitions.CANCEL,
    transitions.MARK_DELIVERED,
    transitions.MARK_DELIVERED_BY_ADMIN,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return [
    transitions.REVIEW_BY_CUSTOMER,
  ].includes(transition);
};

// In this marketplace we do not allow providers to review customers
// export const isProviderReview = transition => {
//   return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
// };

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.MAKE_A_REVIEW_FOR_CART_ITEM,
  ].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.MARK_DELIVERED,
    transitions.MARK_DELIVERED_BY_ADMIN,
    transitions.REVIEW_BY_CUSTOMER,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.CANCEL,
    transitions.CANCEL_BY_ADMIN,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];
