import React from 'react';
import { Link } from 'react-router-dom';

import './ModuleType3.css';

import Module3Image1 from '../../assets/novica-images/modules/module3-image1.png';
import Module3Image2 from '../../assets/novica-images/modules/module3-image2.png';
import Module3Image3 from '../../assets/novica-images/modules/module3-image3.png';

const ModuleType3 = () => {
  return (
    <section className="about-module-type3 ">
      <div className="container">
        <div className="text">
          <div className="heading">
            <span className="intro">Our Movement</span>
            <h5>
              There’s a <span className="c-highlight1">quiet revolution</span>{' '}
              sweeping the world.
            </h5>
          </div>
          <div className="content">
            <p>
              In a world increasingly anonymous and consolidated, we seek
              balance. We crave meaning and authenticity — now more than ever.
            </p>
            <p>
              <Link
                to="/about-us/the-movement/"
                className="button button-offwhite button-animate button-animate-highlight1"
              >
                Join the Movement
                <span className="icon material-symbols-outlined c-highlight1">
                  arrow_right_alt
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="images">
          <div className="image1">
            <img src={Module3Image1} className="m3-img1" />
          </div>
          <div className="image2">
            <img src={Module3Image2} className="m3-img2" />
          </div>
          <div className="image3">
            <img src={Module3Image3} className="m3-img3" />
          </div>
        </div>
        <div className="content-bottom">
          <p>
            In a world increasingly anonymous and consolidated, we seek balance.
            We crave meaning and authenticity — now more than ever.
          </p>
          <p>
            <Link
              to="/about-us/the-movement/"
              className="button button-offwhite button-animate button-animate-highlight1"
            >
              Join the Movement
              <span className="icon material-symbols-outlined c-highlight1">
                arrow_right_alt
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ModuleType3;
