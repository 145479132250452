import moment from 'moment';
import * as log from '../../../../../util/log';
import { storableError } from '../../../../../util/errors';
import {
  isUserAllowedToAccessAdminDashboard,
  prepareParamsForAPIRequest,
  prepareParamsForRecentOrdersAPIRequest,
  prepareTimeRangeTimestamps,
} from './AdminDashboardPage.helpers';
import { compareTypes } from '../../constants';
import {
  getStatisticsAdminDashboard,
  getRecentOrdersAdminDashboard,
} from '../../api';
import { ROWS_PER_PAGE } from '../../components/ProductSalesTableAdmin/ProductSalesTableAdmin';

// ================ Action types ================ //
export const GET_STATISTICS_REQUEST =
  'app/AdminDashboardPage/GET_STATISTICS_REQUEST';
export const GET_STATISTICS_SUCCESS =
  'app/AdminDashboardPage/GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_ERROR =
  'app/AdminDashboardPage/GET_STATISTICS_ERROR';

export const GET_RECENT_ORDERS_REQUEST =
  'app/AdminDashboardPage/GET_RECENT_ORDERS_REQUEST';
export const GET_RECENT_ORDERS_SUCCESS =
  'app/AdminDashboardPage/GET_RECENT_ORDERS_SUCCESS';
export const GET_RECENT_ORDERS_ERROR =
  'app/AdminDashboardPage/GET_RECENT_ORDERS_ERROR';

// ================ Reducer ================ //
const initialState = {
  statistics: null,
  getStatisticsError: null,
  getStatisticsInProgress: false,
  startDate: null,
  endDate: null,
  compareStartDate: null,
  compareEndDate: null,
  comparisonType: compareTypes[0].key,

  recentOrders: [],
  getRecentOrdersInProgress: false,
  getRecentOrdersError: null,
  recentOrdersOffset: 0,
  isLastPageRecentOrders: false,
};

const AdminDashboardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATISTICS_REQUEST:
      // Always assign compareStartDate and compareEndDate because the helper has prepped them
      const newStates = {
        compareStartDate: payload.compareStartDate,
        compareEndDate: payload.compareEndDate,
      };
      if (payload.startDate && payload.endDate) {
        newStates.startDate = payload.startDate;
        newStates.endDate = payload.endDate;
        newStates.recentOrders = [];
        newStates.recentOrdersOffset = 0;
      }
      if (payload.comparisonType) {
        newStates.comparisonType = payload.comparisonType;
      }
      return {
        ...state,
        ...newStates,
        getStatisticsInProgress: true,
        getStatisticsError: null,
        // Reset pagination for recent orders table
        recentOrdersOffset: 0,
        isLastPageRecentOrders: false,
      };
    case GET_STATISTICS_SUCCESS:
      return { ...state, statistics: payload, getStatisticsInProgress: false };
    case GET_STATISTICS_ERROR:
      return {
        ...state,
        getStatisticsError: payload,
        getStatisticsInProgress: false,
      };

    case GET_RECENT_ORDERS_REQUEST:
      return {
        ...state,
        getRecentOrdersInProgress: true,
        getRecentOrdersError: null,
      };
    case GET_RECENT_ORDERS_SUCCESS:
      const newState =
        payload.length < ROWS_PER_PAGE
          ? {
              isLastPageRecentOrders: true,
            }
          : {
              recentOrdersOffset: state.recentOrdersOffset + 10,
            };
      return {
        ...state,
        recentOrders: [...state.recentOrders, ...payload],
        getRecentOrdersInProgress: false,
        ...newState,
      };
    case GET_RECENT_ORDERS_ERROR:
      return {
        ...state,
        getRecentOrdersError: payload,
        getRecentOrdersInProgress: false,
      };

    default:
      return state;
  }
};

export default AdminDashboardPageReducer;

// ================ Action creators ================ //
export const getStatisticsRequest = payload => ({
  type: GET_STATISTICS_REQUEST,
  payload,
});
export const getStatisticsSuccess = payload => ({
  type: GET_STATISTICS_SUCCESS,
  payload,
});
export const getStatisticsError = e => ({
  type: GET_STATISTICS_ERROR,
  payload: e,
});

export const getRecentOrdersRequest = () => ({
  type: GET_RECENT_ORDERS_REQUEST,
});
export const getRecentOrdersSuccess = payload => ({
  type: GET_RECENT_ORDERS_SUCCESS,
  payload,
});
export const getRecentOrdersError = e => ({
  type: GET_RECENT_ORDERS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

const fetchStatistics = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  comparisonType,
}) => async (dispatch, getState, sdk) => {
  try {
    const params = prepareTimeRangeTimestamps({
      startDate,
      endDate,
      hasComparison: !!(compareStartDate && compareEndDate),
      comparisonType,
    });
    dispatch(getStatisticsRequest(params));
    const fetchParams = prepareParamsForAPIRequest({
      startDate: startDate,
      endDate: endDate,
      compareStartDate: compareStartDate,
      compareEndDate: compareEndDate,
    });
    const response = await getStatisticsAdminDashboard(fetchParams);
    dispatch(getStatisticsSuccess(response.data));
  } catch (e) {
    log.error(e, 'fetch-admin-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
    throw e;
  }
};

export const onSelectTimeRange = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  comparisonType,
}) => async (dispatch, getState, sdk) => {
  try {
    await Promise.all([
      dispatch(
        fetchStatistics({
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          comparisonType,
        })
      ),
      dispatch(fetchRecentOrders(0)),
    ]);
  } catch (e) {
    log.error(e, 'fetch-admin-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
  }
};

export const reloadAdminDashboard = () => async (dispatch, getState, sdk) => {
  try {
    await Promise.all([
      dispatch(reloadStatistics()),
      dispatch(fetchRecentOrders(0)),
    ]);
  } catch (e) {
    log.error(e, 'reload-admin-dashboard-failed');
  }
};

const reloadStatistics = () => async (dispatch, getState, sdk) => {
  try {
    const {
      startDate,
      compareStartDate,
      compareEndDate,
      comparisonType,
    } = getState().AdminDashboardPage;

    const endDate = moment()
      .utc()
      .format();

    dispatch(
      fetchStatistics({
        startDate,
        compareStartDate,
        endDate,
        compareEndDate,
        comparisonType,
      })
    );
  } catch (e) {
    log.error(e, 'reload-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
  }
};

export const fetchRecentOrders = overrideOffsetMaybe => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(getRecentOrdersRequest());
    const offset =
      overrideOffsetMaybe ?? getState().AdminDashboardPage.recentOrdersOffset;
    const { startDate, endDate } = getState().AdminDashboardPage;
    const params = prepareParamsForRecentOrdersAPIRequest({
      startDate,
      endDate,
      offset,
    });
    const response = await getRecentOrdersAdminDashboard(params);
    dispatch(getRecentOrdersSuccess(response.data));
  } catch (e) {
    log.error(e, 'fetch-recent-orders-failed');
    dispatch(getRecentOrdersError(storableError(e)));
  }
};

const fetchDefaultAdminStatistics = () => async (dispatch, getState, sdk) => {
  try {
    const startDate = moment()
      .subtract(30, 'days')
      .startOf('day')
      .utc()
      .format();
    const endDate = moment()
      .utc()
      .format();
    return await dispatch(fetchStatistics({ startDate, endDate }));
  } catch (e) {
    log.error(e, 'fetch-default-admin-statistics-failed');
  }
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  try {
    const currentUser = getState().user.currentUser;
    if (!isUserAllowedToAccessAdminDashboard(currentUser)) {
      return dispatch(getStatisticsError('user-not-allowed'));
    }
    return Promise.all([
      dispatch(fetchDefaultAdminStatistics()),
      dispatch(fetchRecentOrders(0)),
    ]);
  } catch (e) {
    log.error(e, 'load-data-admin-dashboard-failed');
  }
};
