import classNames from 'classnames';
import { array, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUserById } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import Avatar from '../Avatar/Avatar';
import ReviewRating from '../ReviewRating/ReviewRating';
import UserDisplayName from '../UserDisplayName/UserDisplayName';
import AbbreviationAvatar from '../AbbreviationAvatar/AbbreviationAvatar';

import css from './CustomReviews.module.css';

const { UUID } = sdkTypes;

const Review = props => {
  const {
    review,
    title,
    dateReviewed,
    intl,
    authorId,
    rating,
    displayName,
  } = props;
  const author = useSelector(state => getUserById(state, new UUID(authorId)));

  const dateString = intl.formatDate(dateReviewed, {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });

  return (
    <div className={css.review}>
      <div className={css.authorInfo}>
        {author ? (
          <Avatar className={css.avatar} user={author} disableProfileLink />
        ) : (
          <AbbreviationAvatar displayName={displayName} />
        )}
        <div className={css.reviewInfo}>
          {author ? (
            <UserDisplayName
              className={css.authorName}
              user={author}
              intl={intl}
            />
          ) : (
            <p className={css.authorName}>{displayName}</p>
          )}
          <div className={css.rating}>
            <ReviewRating
              rating={rating}
              className={css.reviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
            {/* <p className={css.date}>{dateString}</p> */}
          </div>
        </div>
      </div>

      <p className={css.reviewTitle}>{title}</p>
      <p className={css.reviewContent}>{review}</p>
    </div>
  );
};

const CustomReviews = props => {
  const { className, rootClassName, reviews } = props;
  const classes = classNames(rootClassName || css.root, className);
  const intl = useIntl();
  return (
    <ul className={classes}>
      {reviews.map(data => {
        const {
          recordId,
          review,
          title,
          rating,
          displayName,
          dateReviewed,
          authorId,
        } = data;
        return (
          <li key={recordId} className={css.reviewItem}>
            <Review
              review={review}
              title={title}
              rating={rating}
              displayName={displayName}
              intl={intl}
              dateReviewed={dateReviewed}
              authorId={authorId}
            />
          </li>
        );
      })}
    </ul>
  );
};

CustomReviews.defaultProps = {
  className: '',
  children: '',
  reviews: [],
};

CustomReviews.propTypes = {
  className: string,
  rootClassName: string,
  reviews: array,
};

export default CustomReviews;
