import { notifySellerSignupSuccess } from '../../ducks/auth.duck';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { denormalisedResponseEntities } from '../../util/data';

export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};

export const loadDataForSellerLogin = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  const currentUserRes = await sdk.currentUser.show();
  const currentUser = denormalisedResponseEntities(currentUserRes)[0];
  if (!currentUser) {
    return Promise.resolve();
  }
  const isUserVerifiedAsSeller = currentUser.attributes.profile.metadata.isApprovedSeller;
  if (currentUser && !isUserVerifiedAsSeller) {
    await dispatch(notifySellerSignupSuccess(true));
  }
  return Promise.resolve();
};
