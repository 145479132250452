import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { useSelector } from 'react-redux';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { formatPrice } from '../../util/price.helpers';
import { formatMoney } from '../../util/currency';
import { isBookingProcessAlias } from '../../transactions/transaction';
import ListingZoomIcon from '../ListingZoomIcon/ListingZoomIcon';
import _ from 'lodash';
import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000,
});

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);

  const formattedPrice = formatMoney(intl, price);

  const isOnsale = false;

  return (
    <div
      className={`${css.price} button button-small pill productprice fixedwidth`}
    >
      <div className={`${css.priceValue} ${css.onsale}`}>
        {formattedPrice}
        {isOnsale ? (
          <span class={css['productprice-presale']}>
            <span class={`${css['_price']} price`}>$199.99</span>
            <span class="percentoff">(50% off)</span>
          </span>
        ) : null}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage
            id="ListingCard.perUnit"
            values={{ unitType: publicData?.unitType }}
          />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    showZoomIcon = false,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k =>
        k.startsWith(variantPrefix)
      )
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const productSlug = publicData.productName;

  const { storeNameLabel } = author.attributes.profile.publicData || {};

  const params = {
    productSlug: productSlug || slug,
    id,
  };
  const imageItems = _.map(
    currentListing.images,
    ({ attributes: { variants } }) => {
      return _.map(_.keys(variants), v => variants[v].url)[0];
    }
  );

  return (
    <NamedLink className={classes} name="ListingPage" params={params}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <PriceMaybe
          price={price}
          publicData={publicData}
          config={config}
          intl={intl}
        />
        {showZoomIcon && (
          <ListingZoomIcon
            onManageDisableScrolling={onManageDisableScrolling}
            openLargePicModal={() => props.openLargePicModal(imageItems)}
          />
        )}
      </AspectRatioWrapper>
      <div className={`${css.info} ${css.details}`}>
        <div className={css.mainInfo}>
          <div className={`${css.title} ${css.productname}`}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={`${css.authorInfo} ${css.artistname}`}>
              <FormattedMessage
                id="ListingCard.author"
                values={{
                  authorName:
                    publicData.makerName || storeNameLabel || authorName,
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
