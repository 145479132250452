import isEmpty from 'lodash/isEmpty';
import defaultConfig from '../../config/configDefault';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findProductVariationByKey } from '../../util/cart';

const { Money } = sdkTypes;

/**
 * This function will calculate the total price of the cart.
 *
 * @param {array} item
 * @returns {Money} total
 */
export const calcCartTotal = item => {
  if (item.length === 0) return new Money(0, defaultConfig.currency);
  const { currency } = item[0].price;
  const total = item.reduce((acc, elem) => acc + elem.price.amount * elem.quantity, 0);
  return new Money(total, currency);
}

/**
 * This function will check if the product is still valid (instock, available).
 * Otherwise it will mark this product is not valid.
 *
 * @param {array} cartData
 * @returns {array} updatedCartData
 */
export const validateProductInCart = (cartData = []) => {
  return cartData.map(item => {
    const {
      variationMaybe,
      attributes: { publicData },
      quantity,
    } = item;
    const { variationOptions = [], variationKeys = [], stock: productDefaultStock = 0 } = publicData;

    const variationSelectKey = variationMaybe && !isEmpty(variationKeys)
      ? variationKeys.map(key => variationMaybe[key]).join('-')
      : null;

    const selectedProductVariant = !!variationSelectKey
      ? findProductVariationByKey(variationOptions, variationSelectKey)
      : {};

    const { stock: productVariantStock = 0, variationSKU } = selectedProductVariant || {};

    const isOutOfStock = !!variationSelectKey
      ? productVariantStock === 0
      : productDefaultStock === 0;

    const isNotEnoughStock = !!variationSelectKey
      ? productVariantStock > 0 && productVariantStock < quantity
      : productDefaultStock > 0 && productDefaultStock < quantity;

    const isRawProductNotAvailable =
      isEmpty(variationMaybe) && !isEmpty(variationOptions) && !isEmpty(variationKeys);
    const isVariantProductNotAvailable =
      !isEmpty(variationMaybe) && isEmpty(variationOptions) && isEmpty(variationKeys);


    if (isRawProductNotAvailable || isVariantProductNotAvailable) {
      return { ...item, isProductNotAvailable: true, isOutOfStock, isNotEnoughStock, variationSKU };
    }
    return { ...item, isOutOfStock, isNotEnoughStock, variationSKU };
  })
};
