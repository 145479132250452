// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';
import qs from 'qs';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

const handleResponse = res => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader
    ? contentTypeHeader.split(';')[0]
    : null;

  if (res.status >= 400) {
    return res.json().then(data => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  } else if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, {
    typeHandlers,
    verbose: appSettings.sdk.transitVerbose,
  });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader
      ? contentTypeHeader.split(';')[0]
      : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const upload = (path, file, data = {}) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}`;
  const formData = new FormData();
  formData.append('file', file);

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  const options = {
    method: 'POST',
    credentials: 'include',
    body: formData,
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

const get = (path, query) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}${
    query ? `?${qs.stringify(query, { encodeValuesOnly: true })}` : ''
  }`;

  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

const put = (path, body) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const queryOwnListingByAuthorId = authorId => queries => {
  return get(`/api/own-listings/${authorId}`, queries);
};

export const fetchCurrencyRates = currency => {
  return get(`/v1/custom-api/currency/rates/${currency.toLowerCase()}`);
};
export const approveListing = ({ listingId, userId }) => {
  return post(`/api/listings/${listingId}/approve`, { userId });
};

export const queryCartListing = params => {
  return get(`/api/listings/query`, { params });
};

export const validateCoupon = ({ code, listingId, totalAmount }) => {
  return post(`/api/coupon/validate`, {
    code,
    listingId,
    totalAmount,
  });
};

export const uploadFile = file => {
  return upload(`/api/product/import`, file);
};

export const notifySellerSignupEvent = () => {
  return get(`/api/seller/signup/success`);
};

export const checkSellerOnboardingSuccess = () => {
  return get(`/api/seller/onboarding/success`);
};

export const getTaxCodeByProductType = productType => {
  return get(`/api/products/tax-code`, { productType });
};

export const calculateSalesTax = body => {
  return post(`/api/sales-tax/calculate`, body);
};

export const getProductDetails = () => {
  return get(`/api/product/details`);
};

export const getProductFilters = () => {
  return get(`/api/product/filters`);
};

export const validateCouponForGuest = ({
  code,
  listingId,
  totalAmount,
  email,
}) => {
  return post(`/api/coupon/guest/validate`, {
    code,
    listingId,
    totalAmount,
    email,
  });
};

export const checkEmailExists = email => {
  return get(`/api/email/check-exist`, { email });
};

export const verifyEmail = () => {
  return get(`/api/email/verify`);
};

export const getProductCategories = () => get(`/api/product/categories`);

export const findExistListing = authorId => queries =>
  get(`/api/listings/${authorId}/find`, queries);

export const getTagRecordsExistInAirtable = queries =>
  get('/api/product/tags/query', queries);

export const addTagsToAirtable = body => post('/api/product/tags', body);

export const updateTagsToAirtable = body => put('/api/product/tags', body);

export const getSectionData = () => get('/api/section-data/query');

export const fetchSoldListings = queries =>
  get('/api/product/transactions', queries);

export const queryProductReviews = params =>
  get('/api/product/review/query', params);

export const queryArtisanReviews = params =>
  get('/api/artisan/review/query', params);

export const createProductReview = body => post('/api/product/review', body);

export const getStatisticsSellerDashboard = params => 
  get('/api/seller/dashboard/statistics', params);

export const generateImportingTemplate = body =>
  post('/api/importing-template/generate', body);
  
export const updateSellerEthicalPledge = body =>
  put('/api/seller/ethical-pledge', body);
