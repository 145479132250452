import { groupBy, pick, values, get, isEmpty } from 'lodash';

const CART_STORED_KEY = `STORED_CART`;

export const checkIsSavedGuestCart = (storageKey = CART_STORED_KEY) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedData = window.localStorage.getItem(storageKey);

    const { isSavedGuestCart } = storedData ? JSON.parse(storedData) : {};

    return isSavedGuestCart;
  }

  return false;
}

export const getStoredCart = (storageKey = CART_STORED_KEY) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedData = window.localStorage.getItem(storageKey);

    const { cartData, isSavedGuestCart } = storedData ? JSON.parse(storedData) : {};

    return { cartData, isSavedGuestCart };
  }

  return {};
};

export const storeCart = (
  cartData = [],
  { storageKey = CART_STORED_KEY, isSavedGuestCart = false } = {}
) => {
  if (typeof window === 'undefined') {
    return;
  }

  const storedData = {
    cartData,
    isSavedGuestCart,
  };

  const storableData = JSON.stringify(storedData);
  window.localStorage.setItem(storageKey, storableData);
};

const MAX_ITEMS = 100;

export const getCartData = currentUser => {
  const { cartData: cartDataInUserProfile = [] } = get(
    currentUser,
    'attributes.profile.privateData',
    {}
  );
  const { cartData: cartDataInLocal = [], isSavedGuestCart } = getStoredCart();

  const validateCartDataInLocal = isSavedGuestCart
    ? []
    : cartDataInLocal.filter(item => item.authorId !== currentUser?.id?.uuid);

  const rawCartData = [...cartDataInUserProfile, ...validateCartDataInLocal];

  const groupedByItem = groupBy(rawCartData, elem =>
    JSON.stringify(pick(elem, ['listingId', 'variationMaybe']))
  );

  const cartData = values(groupedByItem)
    .map(group => {
      const item = group[0];
      const quantity = group.reduce((total, elem) => total + elem.quantity, 0);

      return {
        ...item,
        quantity,
      };
    })
    .slice(0, MAX_ITEMS);

  return { cartData };
};

export const findProductVariationByKey = (variationOptions = [], key) => {
  if (!key) {
    return {};
  }
  return (
    variationOptions.find(option => {
      const { key: optionKey } = option;
      return optionKey.toString() === key.toString();
    }) || {}
  );
};

export const checkUserHasFinishedSelectVariation = (
  variationKeys = [],
  productVariationSelected = []
) => {
  if (isEmpty(variationKeys) || isEmpty(productVariationSelected)) {
    return false;
  }

  const productVariationSelectedKeys = productVariationSelected.split('-');

  return (
    !isEmpty(productVariationSelectedKeys.pop()) ||
    productVariationSelectedKeys.length === variationKeys.length
  );
};