export const customConfig = {
  productAttributesAirtable: {
    productAttributesTable: {
      productTypeVariable: process.env.REACT_APP_AIRTABLE_PRODUCT_TYPE_VARIABLE,
      otherProductTypeVariable:
        process.env.REACT_APP_AIRTABLE_OTHER_PRODUCT_TYPE_VARIABLE,
    },
  },
  currencyConfig: {
    supportedCurrency: process.env.REACT_APP_CURRENCY_SUPPORT
      ? process.env.REACT_APP_CURRENCY_SUPPORT.split(',')
      : ['USD', 'CAD', 'EUR', 'MXN', 'GBP', 'INR'],
    cachePrefix: 'CACHE_KEY_FOR_CURRENCY',
  },
  typeFormEthicalPledgedId: process.env.REACT_APP_TYPEFORM_ETHICAL_PLEDGED_ID,
};
