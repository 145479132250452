import React, { memo, useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';

const TypeFormWidget = memo(function TypeFormWidget(props) {
  const { id, className, ...rest } = props;
  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      const ref = createWidget(id, { ...rest, container: container.current });
      return () => {
        ref.unmount();
      };
    }
  }, [id, rest]);

  return <div className={className} ref={container} />;
});

export default TypeFormWidget;
