import React from 'react';
import isArray from 'lodash/isArray';
import { FIELD_TYPES, NUMBER_TYPES, OTHER_OPTION } from './types';
import {
  composeValidators,
  maxItem,
  maxItemFieldArrayCheckbox,
  required,
  requiredFieldArrayCheckbox,
  validDecimal,
  validInteger,
} from './validators';
import { FieldCheckboxGroup, FieldSelect, FieldTextInput } from '../components';
import { splitValuesFromString } from './data';
import { OnChange } from 'react-final-form-listeners';

const MAX_ITEMS_ERROR_MESSAGE = 'This field exceeds maximum limit';
const LIMIT_MAX_ITEMS = 30;

export const getSuitableDefaultField = (fieldData, configOptions = {}) => {
  const {
    name,
    field_name,
    field_type: FieldComponent,
    placeholder,
    required: isRequired,
    values,
    blank_error_text,
    index,
    className,
    dependentFields,
    formApi
  } = fieldData;

  const { removeOtherOption, handleChangeVariationValues } = configOptions;

  const validatorMaybe =
    isRequired && blank_error_text ? required(blank_error_text) : () => {};

  const fieldProps = {
    name,
    label: field_name,
    validate: validatorMaybe,
    placeholder,
    key: index,
    id: name,
    className,
  };

  const fieldComponentHandler = {
    [FIELD_TYPES.FIELD_SINGLE_SELECT]: () => {
      const valuesToDisplay = values
        .map(valueItem => {
          const [key, label] = valueItem.split('_');
          if (removeOtherOption && key === OTHER_OPTION) {
            return null;
          }

          return {
            key,
            label,
          };
        })
        .filter(Boolean);

      return (
        <FieldSelect {...fieldProps}>
          <option disabled value="">
            {placeholder}
          </option>
          {valuesToDisplay.map(item => {
            return (
              <option key={item.key} value={item.key}>
                {item.label}
              </option>
            );
          })}
        </FieldSelect>
      );
    },
    [FIELD_TYPES.FIELD_MULTIPLE_SELECT]: () => {
      const valuesToDisplay = values.map(valueItem => {
        const [key, label] = valueItem.split('_');
        return {
          key,
          label,
        };
      });
      if (removeOtherOption) {
        valuesToDisplay.splice(-1);
      }
      return (
        <FieldCheckboxGroup
          {...fieldProps}
          options={valuesToDisplay}
          twoColumns
          validate={composeValidators(
            isRequired && blank_error_text
              ? requiredFieldArrayCheckbox(blank_error_text)
              : () => {},
            maxItemFieldArrayCheckbox(MAX_ITEMS_ERROR_MESSAGE, LIMIT_MAX_ITEMS)
          )}
        />
      );
    },
    [FIELD_TYPES.FIELD_TEXT_INPUT]: () => {
      return (
        <FieldTextInput
          {...fieldProps}
          type="text"
          validate={composeValidators(
            validatorMaybe,
            maxItem(MAX_ITEMS_ERROR_MESSAGE, LIMIT_MAX_ITEMS)
          )}
        />
      );
    },
  };

  const handleChangeValues = () => {
    handleChangeVariationValues();
    if (name === 'product_type') {
      for (const dependentField of dependentFields) {
        formApi.change(dependentField.name, null);

      }
    }
  };

  return (
    <>
      {fieldComponentHandler[FieldComponent]()}
      <OnChange name={name}>{handleChangeValues}</OnChange>
    </>
  );
};

export const getSuitableCustomField = (fieldData, configOptions = {}) => {
  const {
    name,
    field_name,
    field_type: FieldComponent,
    placeholder,
    required: isRequired,
    blank_error_text,
    wrong_error_text,
    index,
    className,
    type,
  } = fieldData;

  const { handleChangeVariationValues } = configOptions;

  const requiredValidatorMaybe =
    isRequired && blank_error_text ? required(blank_error_text) : () => {};

  const fieldProps = {
    name,
    label: field_name,
    placeholder,
    key: index,
    id: name,
    className,
  };

  const fieldComponentHandler = {
    [FIELD_TYPES.FIELD_NUMBER_INPUT]: () => {
      const formatValidatorMaybe =
        type === NUMBER_TYPES.DECIMAL
          ? validDecimal(wrong_error_text)
          : type === NUMBER_TYPES.INTEGER
          ? validInteger(wrong_error_text)
          : () => {};

      return (
        <FieldTextInput
          {...fieldProps}
          type="number"
          validate={composeValidators(
            requiredValidatorMaybe,
            formatValidatorMaybe
          )}
        />
      );
    },
  };

  return (
    <>
      {fieldComponentHandler[FieldComponent]()}
      <OnChange name={name}>{handleChangeVariationValues}</OnChange>
    </>
  );
};

/**
 *
 * @param {*}
 * keyValues: ['red', 'yellow']
 * keyName: the key name of value list: 'color'
 * productType: type of product that has dependent fields: 'rings'
 * @returns {Object}
 * {
 *    key: 'color',
 *    label: 'Color',
 *    values: [
 *      {
 *        key: 'red',
 *        label: 'Red'
 *      },
 *      {
 *        key: 'yellow',
 *        label: 'Yellow'
 *      }
 *    ]
 * }
 */
export const getRealProductAttributesValueFromAirtable = ({
  keyValues,
  keyName,
  productAttributes,
  productType
}) => {
  const variationField = productAttributes.find(
    attr => attr.name === keyName && attr.depend_on_value === productType
  );

  const convertedKeyValues = isArray(keyValues) ? keyValues.map(v => v.toString()) : [];

  const storedVariationValues =
    !!variationField && isArray(keyValues)
      ? variationField.values?.filter(value =>
        convertedKeyValues.includes(value.split('_')[0])
        ) || []
      // in case new other variation added, its value will be saved like a list data on airtable
      : keyValues?.options || [];

  const values =
    typeof keyValues === 'string'
      ? splitValuesFromString(keyValues)
      : // Get the real value from Airtable with the corresponding key (key_Value)
        storedVariationValues?.map(value => ({
          key: value.split('_')[0],
          label: value.split('_')[1],
        }));

  return {
    label: variationField?.field_name || keyValues?.label,
    values,
    key: keyName,
  };
};

/**
 * @param {Array} variations variations of a listing, it can be formatted like:
 * [{
 *    key: 'color',
 *    label: 'Color',
 *    values: [
 *      {
 *        key: 'red',
 *        label: 'Red'
 *      }
 *    ]
 * }]
 *@param {Object} formValues form values of the selection of user, it can be formatted like:
 * {
 *    color: 'red'
 * }
 * @return {Array} Returns an array of label value like ["Color"]
 *
 **/
export const getVariationValues = (variations, formValues) => {
  return variations.reduce((res, variation) => {
    if (!!formValues?.[variation.key]) {
      const selectedVariation = variation.values.find(
        item => item.key === formValues[variation.key]
      );
      res.push(selectedVariation.label);
    }

    return res;
  }, []);
};

export const getLabelOption = ({ options = [], keyValue }) => {
  const option = options.find(o => o.split('_').includes(keyValue));

  if (option) {
    return option.split('_')[1];
  }

  return null;
};

export const createFilterOptionsForDynamicFields = options => {
  return options.map(o => ({ key: o.split('_')[0], label: o.split('_')[1] }));
};
