import pick from 'lodash/pick';
import {
  checkEmailExists,
  initiatePrivileged,
  transactionLineItems,
  transitionPrivileged,
  validateCouponForGuest,
  verifyEmail,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { login } from '../../ducks/auth.duck';
import { USER_TYPE } from '../../util/types';
import { validateAddress } from '../../extensions/googleMaps/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/GuestCheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/GuestCheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/GuestCheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/GuestCheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/GuestCheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/GuestCheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/GuestCheckoutPage/CONFIRM_PAYMENT_ERROR';

export const CHECK_DISCOUNT_CODE_REQUEST =
  'app/GuestCheckoutPage/CHECK_DISCOUNT_CODE_REQUEST';
export const CHECK_DISCOUNT_CODE_SUCCESS =
  'app/GuestCheckoutPage/CHECK_DISCOUNT_CODE_SUCCESS';
export const CHECK_DISCOUNT_CODE_ERROR =
  'app/GuestCheckoutPage/CHECK_DISCOUNT_CODE_ERROR';

export const REMOVE_DISCOUNT_CODE_DATA =
  'app/GuestCheckoutPage/REMOVE_DISCOUNT_CODE_DATA';

export const FETCH_LINE_ITEMS_REQUEST =
  'app/GuestCheckoutPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
  'app/GuestCheckoutPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR =
  'app/GuestCheckoutPage/FETCH_LINE_ITEMS_ERROR';

export const CHECK_EMAIL_EXISTS_REQUEST =
  'app/GuestCheckoutPage/CHECK_EMAIL_EXISTS_REQUEST';
export const CHECK_EMAIL_EXISTS_SUCCESS =
  'app/GuestCheckoutPage/CHECK_EMAIL_EXISTS_SUCCESS';
export const CHECK_EMAIL_EXISTS_ERROR =
  'app/GuestCheckoutPage/CHECK_EMAIL_EXISTS_ERROR';

export const CREATE_FLEX_USER_REQUEST = 'app/GuestCheckoutPage/CREATE_FLEX_USER_REQUEST';
export const CREATE_FLEX_USER_SUCCESS = 'app/GuestCheckoutPage/CREATE_FLEX_USER_SUCCESS';
export const CREATE_FLEX_USER_ERROR = 'app/GuestCheckoutPage/CREATE_FLEX_USER_ERROR';

export const VALIDATE_ADDRESS_REQUEST = 'app/GuestCheckoutPage/VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS = 'app/GuestCheckoutPage/VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_ERROR = 'app/GuestCheckoutPage/VALIDATE_ADDRESS_ERROR';

// ================ Reducer ================ //

const initialState = {
  cartItemListings: [],
  listing: null,
  orderData: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  discountCodeData: null,
  checkDiscountCodeInProgress: false,
  checkDiscountCodeError: null,
  lineItems: [],
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  guestEmail: null,
  isGuestEmailExists: false,
  checkEmailExistsInProgress: false,
  checkEmailExistsError: null,
  createFlexUserInProgress: false,
  createFlexUserError: null,
  validateAddressResult: null,
  validateAddressInProgress: false,
  validateAddressError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case CHECK_DISCOUNT_CODE_REQUEST:
      return {
        ...state,
        checkDiscountCodeInProgress: true,
        checkDiscountCodeError: null,
        discountCodeData: null,
      };
    case CHECK_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        checkDiscountCodeInProgress: false,
        discountCodeData: payload,
      };
    case CHECK_DISCOUNT_CODE_ERROR:
      return {
        ...state,
        checkDiscountCodeInProgress: false,
        checkDiscountCodeError: payload,
      };
    
    case REMOVE_DISCOUNT_CODE_DATA:
      return {
        ...state,
        discountCodeData: null,
      };

    case FETCH_LINE_ITEMS_REQUEST:
      return {
        ...state,
        lineItems: [],
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null,
      };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        fetchLineItemsError: payload,
      };

    case CHECK_EMAIL_EXISTS_REQUEST:
      return {
        ...state,
        isGuestEmailExists: false,
        checkEmailExistsInProgress: true,
        checkEmailExistsError: null,
      };
    case CHECK_EMAIL_EXISTS_SUCCESS:
      return {
        ...state,
        guestEmail: payload.email,
        isGuestEmailExists: payload.isEmailExists,
        checkEmailExistsInProgress: false,
      };
    case CHECK_EMAIL_EXISTS_ERROR:
      return {
        ...state,
        checkEmailExistsInProgress: false,
        checkEmailExistsError: payload,
      };
    
    case CREATE_FLEX_USER_REQUEST:
      return {
        ...state,
        createFlexUserInProgress: true,
        createFlexUserError: null,
      };
    case CREATE_FLEX_USER_SUCCESS:
      return {
        ...state,
        createFlexUserInProgress: false,
      };
    case CREATE_FLEX_USER_ERROR:
      return {
        ...state,
        createFlexUserInProgress: false,
        createFlexUserError: payload,
      };

    case VALIDATE_ADDRESS_REQUEST:
      return {
        ...state,
        validateAddressInProgress: true,
        validateAddressError: null,
        validateAddressResult: null,
      };
    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressResult: payload,
      };
    case VALIDATE_ADDRESS_ERROR:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const checkDiscountCodeRequest = () => ({
  type: CHECK_DISCOUNT_CODE_REQUEST,
});
export const checkDiscountCodeSuccess = data => ({
  type: CHECK_DISCOUNT_CODE_SUCCESS,
  payload: data,
});
export const checkDiscountCodeError = e => ({
  type: CHECK_DISCOUNT_CODE_ERROR,
  error: true,
  payload: e,
});
export const removeDiscountCodeData = () => ({
  type: REMOVE_DISCOUNT_CODE_DATA,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const checkEmailExistsRequest = () => ({
  type: CHECK_EMAIL_EXISTS_REQUEST,
});
export const checkEmailExistsSuccess = payload => ({
  type: CHECK_EMAIL_EXISTS_SUCCESS,
  payload,
});
export const checkEmailExistsError = e => ({
  type: CHECK_EMAIL_EXISTS_ERROR,
  error: true,
  payload: e,
});

export const createFlexUserRequest = () => ({
  type: CREATE_FLEX_USER_REQUEST,
});
export const createFlexUserSuccess = () => ({
  type: CREATE_FLEX_USER_SUCCESS,
});
export const createFlexUserError = e => ({
  type: CREATE_FLEX_USER_ERROR,
  error: true,
  payload: e,
});

export const validateAddressRequest = () => ({
  type: VALIDATE_ADDRESS_REQUEST,
});
export const validateAddressSuccess = data => ({
  type: VALIDATE_ADDRESS_SUCCESS,
  payload: data,
});
export const validateAddressError = e => ({
  type: VALIDATE_ADDRESS_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const {
    deliveryMethod,
    quantity,
    bookingDates,
    orderData,
    ...otherOrderParams
  } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const confirmPayment = (transactionId, transitionName, transitionParams = {}) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const checkDiscountCode = ({ code, listingId, totalAmount, email }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(checkDiscountCodeRequest());

  return validateCouponForGuest({ code, listingId, totalAmount, email })
    .then(response => {
      dispatch(checkDiscountCodeSuccess(response));
      return response;
    })
    .catch(e => {
      log.error(e, 'check-discount-code-failed', {
        code,
      });
      return dispatch(checkDiscountCodeError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchLineItemsRequest());
  const { quantity } = orderData;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  transactionLineItems({
    orderData: { ...orderData, ...quantityMaybe },
    listingId,
  })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const checkUserEmailExists = email => (dispatch, getState, sdk) => {
  dispatch(checkEmailExistsRequest());

  return checkEmailExists(email)
    .then(response => {
      const { data: isEmailExists } = response;
      dispatch(checkEmailExistsSuccess({ email, isEmailExists }));
    })
    .catch(e => dispatch(checkEmailExistsError(storableError(e))));
};

export const createFlexUser = params => (dispatch, getState, sdk) => {
  dispatch(createFlexUserRequest());

  const { email, firstName, lastName } = params;

  const generatedPassword = Math.random()
    .toString(36)
    .slice(-8);

  const createUserParams = {
    email,
    password: generatedPassword,
    firstName,
    lastName,
    publicData: { email, isAutoCreateAccount: true, role: USER_TYPE.BUYER },
    privateData: { defaultPassword: generatedPassword },
  };

  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(login(email, generatedPassword)))
    .then(() => verifyEmail())
    .then(() => {
      dispatch(createFlexUserSuccess());
    })
    .catch(e => dispatch(createFlexUserError(storableError(e))));
}

export const validateRecipientAddress = (address, countryCode) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(validateAddressRequest());

  return validateAddress(address, countryCode)
    .then(response => {
      dispatch(validateAddressSuccess(response.result.address));
      return response.result.address;
    })
    .catch(e => {
      log.error(e, 'validate-address-failed', {
        address,
      });
      return dispatch(validateAddressError(storableError(e)));
    });
};