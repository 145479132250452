import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconTag.module.css';

const IconTag = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.35698 3.55013C3.91137 3.55013 3.55013 3.91137 3.55013 4.35698C3.55013 4.80259 3.91137 5.16383 4.35698 5.16383C4.80259 5.16383 5.16383 4.80259 5.16383 4.35698C5.16383 3.91137 4.80259 3.55013 4.35698 3.55013Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.968218 0C0.433486 0 0 0.433486 0 0.968218V6.41563C0 6.67242 0.102008 6.91869 0.283584 7.10026L6.89974 13.7164C7.27785 14.0945 7.89089 14.0945 8.269 13.7164L13.7164 8.269C14.0945 7.89089 14.0945 7.27785 13.7164 6.89974L7.10026 0.283584C6.91869 0.102008 6.67242 0 6.41563 0H0.968218ZM0.968218 0.968218H6.41563L13.0318 7.58437L7.58437 13.0318L0.968218 6.41563V0.968218Z"
      />
    </svg>
  );
};

IconTag.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconTag.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconTag;
