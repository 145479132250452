import React, { useEffect } from 'react';
import { bool, object, string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { getCountryCodes } from '../../translations/countryCodes';
import { Field } from 'react-final-form';
import { FieldTextInput, FieldSelect } from '../../components';
import { statesUS as stateOptions } from '../../config/configStates';

import css from './StripePaymentAddress.module.css';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const US_COUNTRY_CODE = 'US';

const MuiTextField = ({ input, meta, onUnmount, ...rest }) => {
  /*useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);*/
  return (
    <TextField
      {...input}
      {...rest}
      variant='outlined'
      fullWidth
      error={meta.touched && meta.error ? true : false}
      helperText={meta.touched && meta.error ? meta.error : null }
    />
  );
};

const StripePaymentAddress = props => {
  const { className, intl, disabled, form, fieldId, card, locale, values } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: values.country !== US_COUNTRY_CODE ? optionalText : null }
  );
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const stateRequired = (value, allValues) => {
    if (allValues.country === US_COUNTRY_CODE && !value) {
      return intl.formatMessage({ id: 'StripePaymentAddress.stateRequired' });
    }
    return undefined;
  };

  const handleOnChange = event => {
    const value = event.target.value;    
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  // Use the language set in config.localization.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(locale);

  return (
    <div className={className ? className : css.root}>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Field
          id={`${fieldId}.addressLine1`}
          name="addressLine1"
          disabled={disabled}
          render={({ input, ...rest }) => (
            <>
              <MuiTextField
                {...input}
                {...rest}
              />
            </>
          )}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
          sx={{ marginBottom:'2rem', flex:1 }}
        />
        {/*<FieldTextInput
          id={`${fieldId}.addressLine1`}
          name="addressLine1"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
          onUnmount={() => form.change('addressLine1', undefined)}
        />*/}
        <Field
          id={`${fieldId}.addressLine2`}
          name="addressLine2"
          disabled={disabled}
          render={({ input, ...rest }) => (
            <>
              <MuiTextField
                {...input}
                {...rest}
              />
            </>
          )}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
          sx={{ marginBottom:'2rem', flex:1 }}
        />
        {/*<FieldTextInput
          id={`${fieldId}.addressLine2`}
          name="addressLine2"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
          onUnmount={() => form.change('addressLine2', undefined)}
        />*/}
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Field
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          render={({ input, ...rest }) => (
            <>
              <MuiTextField
                {...input}
                {...rest}
              />
            </>
          )}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onChange={event => handleOnChange(event)}
          onUnmount={() => form.change('postal', undefined)}
          sx={{ marginBottom:'2rem', flex:1 }}
        />
        {/*<FieldTextInput
          id={`${fieldId}.postalCode`}
          name="postal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
          onUnmount={() => form.change('postal', undefined)}
          onChange={event => handleOnChange(event)}
        />*/}
        <Field
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          render={({ input, ...rest }) => (
            <>
              <MuiTextField
                {...input}
                {...rest}
              />
            </>
          )}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
          sx={{ marginBottom:'2rem', flex:1 }}
        />
        {/*<FieldTextInput
          id={`${fieldId}.city`}
          name="city"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
          onUnmount={() => form.change('city', undefined)}
        />*/}
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {values.country === US_COUNTRY_CODE ? (
          <Field
            id={`${fieldId}.state`}
            name="state"
            render={({ input, meta, label, options }) => (
              <FormControl fullWidth error={meta.touched && !!meta.error}>
                <InputLabel>{label}</InputLabel>
                <Select
                  {...input}
                  label={label}
                  value={input.value || ''}
                  onChange={(event) => input.onChange(event.target.value)}
                >
                  <MenuItem disabled key="stateDefault" value="">
                    {intl.formatMessage({ id: 'StripePaymentAddress.usStatePlaceholder' })}
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
              </FormControl>
            )}
            options={stateOptions}
            autoComplete="shipping address-level1"
            label={stateLabel}
            validate={stateRequired}
          />
        ) : (
          <Field
            id={`${fieldId}.state`}
            name="state"
            disabled={disabled}
            render={({ input, ...rest }) => (
              <>
                <MuiTextField
                  {...input}
                  {...rest}
                />
              </>
            )}
            type="text"
            autoComplete="billing address-level1"
            label={stateLabel}
            placeholder={statePlaceholder}
            validate={stateRequired}
            onUnmount={() => form.change('state', undefined)}
            sx={{ marginBottom:'2rem' }}
          />
        )}
        {/*values.country === US_COUNTRY_CODE ? (
          <FieldSelect
            id={`${fieldId}.state`}
            name="state"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="shipping address-level1"
            label={stateLabel}
            validate={stateRequired}
          >
            <option disabled value="">
              {intl.formatMessage({ id: 'StripePaymentAddress.usStatePlaceholder' })}
            </option>
            {stateOptions.map(state => {
              return (
                <option key={state.key} value={state.key}>
                  {state.label}
                </option>
              );
            })}
          </FieldSelect>
        ) : (
          <FieldTextInput
            id={`${fieldId}.state`}
            name="state"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="billing address-level1"
            label={stateLabel}
            placeholder={statePlaceholder}
            validate={stateRequired}
            onUnmount={() => form.change('state', undefined)}
          />
        )*/}

        <Field
          id={`${fieldId}.country`}
          name="country"
          render={({ input, meta, label, options }) => (
            <FormControl fullWidth error={meta.touched && !!meta.error}>
              <InputLabel>{label}</InputLabel>
              <Select
                {...input}
                label={label}
                value={input.value || ''}
                onChange={(event) => input.onChange(event.target.value)}
              >
                <MenuItem disabled key="countryDefault" value="">
                  {countryPlaceholder}
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
          )}
          options={countryCodes}
          label={countryLabel}
          validate={countryRequired}
          defaultValue={US_COUNTRY_CODE}
          onChange={() => form.change('state', undefined)}
        />
        {/*<FieldSelect
          id={`${fieldId}.country`}
          name="country"
          disabled={disabled}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
          defaultValue={US_COUNTRY_CODE}
          onChange={() => form.change('state', undefined)}
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>*/}
      </Box>
    </div>
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,
  locale: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
