import React from "react";
import { useIntl } from "react-intl";
import { bool, func, node, string } from "prop-types";
import { Modal, PrimaryButton } from '..';
import { FormattedMessage } from "react-intl";

import css from "./ConfirmModal.module.css";

const ConfirmModal = props => {
  // Props
  const {
    isModalOpen,
    isSubmitting,
    submitError,
    handleCloseModal,
    handleSubmitModal,
    onManageDisableScrolling,
    title,
    description,
    helperText,
    backButtonText,
    submitButtonText,
    children,
  } = props;

  // Hooks
  const intl = useIntl();

  return (
    <Modal
      id="TransactionPage.modalPaymentMethod"
      isOpen={isModalOpen}
      onClose={isSubmitting ? () => {} : handleCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      combinedContainerClassName={css.container}
      usePortal
    >
      <h2 className={css.title}>{title}</h2>
      {description && <p className={css.description}>{description}</p>}
      {children}
      {helperText}
      {submitError && (
        <FormattedMessage id="ConfirmModal.error">
          {text => <p className={css.error}>{text}</p>}
        </FormattedMessage>
      )}
      <div className={css.buttonWrapper}>
        {backButtonText && (
          <PrimaryButton onClick={handleCloseModal} disabled={isSubmitting}>
            {backButtonText ||
              intl.formatMessage({ id: 'ConfirmModal.backButton' })}
          </PrimaryButton>
        )}
        {handleSubmitModal && (
          <PrimaryButton
            className={css.submitButton}
            onClick={handleSubmitModal}
            inProgress={isSubmitting}
          >
            {submitButtonText ||
              intl.formatMessage({ id: 'ConfirmModal.submitButton' })}
          </PrimaryButton>
        )}
      </div>
    </Modal>
  );
}

ConfirmModal.defaultProps = {
  isModalOpen: false,
  isSubmitting: false,
  description: null,
  helperText: null,
};

ConfirmModal.propTypes = {
  isModalOpen: bool,
  handleCloseModal: func,
  handleSubmitModal: func,
  isSubmitting: bool,
  title: string.isRequired,
  description: string,
  helperText: node,
  backButtonText: string,
  submitButtonText: string,
};

export default ConfirmModal;
