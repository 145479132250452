import { storableError } from '../../util/errors';
import _ from 'lodash';
import {
  currentUserShowSuccess,
  fetchCurrentUser,
} from '../../ducks/user.duck';
import { END_OF_PRODUCT_TYPE_SEARCH } from '../../util/types';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { getCartData, storeCart } from '../../util/cart';
import { queryCartListing } from '../../util/api';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { LISTING_STATE_CLOSED } from '../../util/types';
import { groupBy, isEqual, pick, values } from 'lodash';
import { validateProductInCart } from './CartPageHelper';
import { getProductCategories } from '../../util/api';
import { pushAddToCartDataToDataLayer } from '../../util/dataLayer';
import {
  FETCHING_PRODUCT_CATEGORIES_SUCCESS,
  FETCHING_PRODUCT_CATEGORIES_REQUEST,
  FETCHING_PRODUCT_CATEGORIES_ERROR,
} from '../SearchPage/SearchPage.duck';

const MAX_ITEMS = 100;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CartPage/SET_INITIAL_VALUES';

export const UPDATE_SHOPPING_CART = 'app/CartPage/UPDATE_SHOPPING_CART';

export const FETCH_CART_DATA_REQUEST = 'app/CartPage/FETCH_CART_DATA_REQUEST';
export const FETCH_CART_DATA_SUCCESS = 'app/CartPage/FETCH_CART_DATA_SUCCESS';
export const FETCH_CART_DATA_ERROR = 'app/CartPage/FETCH_CART_DATA_ERROR';

export const ADD_ITEM_TO_CART_DATA_REQUEST =
  'app/CartPage/ADD_ITEM_TO_CART_DATA_REQUEST';
export const ADD_ITEM_TO_CART_DATA_SUCCESS =
  'app/CartPage/ADD_ITEM_TO_CART_DATA_SUCCESS';
export const ADD_ITEM_TO_CART_DATA_ERROR =
  'app/CartPage/ADD_ITEM_TO_CART_DATA_ERROR';

export const REMOVE_ITEM_TO_CART_DATA_REQUEST =
  'app/CartPage/REMOVE_ITEM_TO_CART_DATA_REQUEST';
export const REMOVE_ITEM_TO_CART_DATA_SUCCESS =
  'app/CartPage/REMOVE_ITEM_TO_CART_DATA_SUCCESS';
export const REMOVE_ITEM_TO_CART_DATA_ERROR =
  'app/CartPage/REMOVE_ITEM_TO_CART_DATA_ERROR';

export const UPDATE_ITEM_QUANTITY_REQUEST =
  'app/CartPage/UPDATE_ITEM_QUANTITY_REQUEST';
export const UPDATE_ITEM_QUANTITY_SUCCESS =
  'app/CartPage/UPDATE_ITEM_QUANTITY_SUCCESS';
export const UPDATE_ITEM_QUANTITY_ERROR =
  'app/CartPage/UPDATE_ITEM_QUANTITY_ERROR';

export const UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS';
export const UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR =
  'app/CartPage/UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR';

// ================ Reducer ================ //

const initialState = {
  cartProducts: [],
  cartData: [],
  storeData: [],
  fetchCartDataInProgress: false,
  fetchCartDataError: null,
  addedItemId: null,
  addItemInProgress: false,
  addItemError: null,
  removeItemInProgress: false,
  removeItemError: null,
  updateItemQuantityInProgress: false,
  updateItemQuantityError: null,
  prepareItemBeforeCheckoutError: null,
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...state, cartProducts: payload };

    case UPDATE_SHOPPING_CART:
      return { ...state, cartProducts: payload };

    case FETCH_CART_DATA_REQUEST:
      return {
        ...state,
        fetchCartDataInProgress: true,
        fetchCartDataError: null,
      };
    case FETCH_CART_DATA_SUCCESS:
      const updateCartProductsMaybe = payload.newCartData
        ? { cartProducts: payload.newCartData }
        : {};
      return {
        ...state,
        fetchCartDataInProgress: false,
        cartData: payload.listingData,
        storeData: payload.storeListings,
        ...updateCartProductsMaybe,
      };
    case FETCH_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchCartDataInProgress: false,
        fetchCartDataError: payload,
      };

    case ADD_ITEM_TO_CART_DATA_REQUEST:
      return { ...state, addItemInProgress: true, addItemError: null };
    case ADD_ITEM_TO_CART_DATA_SUCCESS: {
      return {
        ...state,
        addItemInProgress: false,
        addedItemId: payload.listingId,
        cartProducts: payload.newCartData,
      };
    }
    case ADD_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, addItemInProgress: false, addItemError: payload };

    case REMOVE_ITEM_TO_CART_DATA_REQUEST:
      return { ...state, removeItemInProgress: true, removeItemError: null };
    case REMOVE_ITEM_TO_CART_DATA_SUCCESS: {
      const { newCartData, newCartProducts, newStoreData } = payload;

      return {
        ...state,
        removeItemInProgress: false,
        cartData: newCartData,
        cartProducts: newCartProducts,
        storeData: newStoreData
      };
    }
    case REMOVE_ITEM_TO_CART_DATA_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        removeItemInProgress: false,
        removeItemError: payload,
      };

    case UPDATE_ITEM_QUANTITY_REQUEST:
      return {
        ...state,
        updateItemQuantityInProgress: true,
        updateItemQuantityError: null,
      };
    case UPDATE_ITEM_QUANTITY_SUCCESS: {
      return {
        ...state,
        updateItemQuantityInProgress: false,
        cartData: payload.newCartData,
        cartProducts: payload.newCartProducts,
      };
    }
    case UPDATE_ITEM_QUANTITY_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        updateItemQuantityInProgress: false,
        updateItemQuantityError: payload,
      };

    case UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST:
      return {
        ...state,
        prepareItemBeforeCheckoutError: null,
      };
    case UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS: {
      return {
        ...state,
        cartData: payload.newCartData,
        cartProducts: payload.newCartProducts,
      };
    }
    case UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        prepareItemBeforeCheckoutError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const setInitialValues = payload => ({ type: SET_INITIAL_VALUES, payload });

const updateShoppingCartAction = payload => ({
  type: UPDATE_SHOPPING_CART,
  payload,
});

const fetchCartDataRequest = () => ({ type: FETCH_CART_DATA_REQUEST });
const fetchCartDataSuccess = payload => ({
  type: FETCH_CART_DATA_SUCCESS,
  payload,
});
const fetchCartDataError = e => ({
  type: FETCH_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const addItemToCartDataRequest = () => ({
  type: ADD_ITEM_TO_CART_DATA_REQUEST,
});
const addItemToCartDataSuccess = payload => ({
  type: ADD_ITEM_TO_CART_DATA_SUCCESS,
  payload,
});
const addItemToCartDataError = e => ({
  type: ADD_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const removeItemToCartDataRequest = () => ({
  type: REMOVE_ITEM_TO_CART_DATA_REQUEST,
});
const removeItemToCartDataSuccess = payload => ({
  type: REMOVE_ITEM_TO_CART_DATA_SUCCESS,
  payload,
});
const removeItemToCartDataError = e => ({
  type: REMOVE_ITEM_TO_CART_DATA_ERROR,
  error: true,
  payload: e,
});

const updateItemQuantityRequest = () => ({
  type: UPDATE_ITEM_QUANTITY_REQUEST,
});
const updateItemQuantitySuccess = payload => ({
  type: UPDATE_ITEM_QUANTITY_SUCCESS,
  payload,
});
const updateItemQuantityError = e => ({
  type: UPDATE_ITEM_QUANTITY_ERROR,
  error: true,
  payload: e,
});

const updateCartDataAfterCheckoutRequest = () => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_REQUEST,
});
const updateCartDataAfterCheckoutSuccess = payload => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_SUCCESS,
  payload,
});
const updateCartDataAfterCheckoutError = e => ({
  type: UPDATE_CART_DATA_AFTER_CHECKOUT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const setInitialValuesForShoppingCart = () => async (
  dispatch,
  getState,
  sdk
) => {
  const { currentUser } = getState().user;

  if (!currentUser) {
    await dispatch(fetchCurrentUser());
  }

  const { currentUser: fetchedCurrentUser } = getState().user;

  const { cartData = [] } = getCartData(fetchedCurrentUser);

  dispatch(setInitialValues(cartData));
};

export const updateShoppingCart = newCartData => (dispatch, getState, sdk) => {
  dispatch(updateShoppingCartAction(newCartData));
};

export const addItemToCart = (
  listing,
  currentCartData,
  itemOption = {}
) => async (dispatch, getState, sdk) => {
  dispatch(addItemToCartDataRequest());

  const {
    id: { uuid: listingId },
    author: {
      id: { uuid: authorId },
    },
  } = listing;

  const { isAuthenticated } = getState().auth;

  if (currentCartData.length === MAX_ITEMS) {
    dispatch(addItemToCartDataError(storableError(new Error('Cart is full'))));
    return;
  }

  const newCartDataRaw = [
    ...currentCartData,
    { listingId, checked: true, authorId, ...itemOption },
  ];

  const groupedByItem = groupBy(newCartDataRaw, elem =>
    JSON.stringify(pick(elem, ['listingId', 'variationMaybe']))
  );

  const newCartData = values(groupedByItem).map(group => {
    const item = group[0];
    const quantity = group.reduce((total, elem) => total + elem.quantity, 0);

    return {
      ...item,
      quantity,
    };
  });

  if (!isAuthenticated) {
    storeCart(newCartData);
    dispatch(addItemToCartDataSuccess({ listingId, newCartData }));
    pushAddToCartDataToDataLayer(listing, itemOption);
    return true;
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartData } })
    .then(() => {
      dispatch(addItemToCartDataSuccess({ listingId, newCartData }));
      pushAddToCartDataToDataLayer(listing, itemOption);
      return true;
    })
    .catch(e => {
      console.error(e);
      dispatch(addItemToCartDataError(storableError(e)));
      return false;
    });
};

export const removeItemFromCart = ({ listingId, variationMaybe }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(removeItemToCartDataRequest());

  const { isAuthenticated } = getState().auth;

  const { cartData, cartProducts, storeData } = getState().CartPage;

  const newCartProducts = cartProducts.filter(item => {
    const {
      listingId: itemListingId,
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['listingId', 'variationMaybe']);
    return !isEqual(
      { listingId: itemListingId, variationMaybe: itemVariationMaybe },
      {
        listingId,
        variationMaybe,
      }
    );
  });

  const newCartData = cartData.filter(item => {
    const {
      id: { uuid: itemListingId },
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['id.uuid', 'variationMaybe']);
    return !isEqual(
      { listingId: itemListingId, variationMaybe: itemVariationMaybe },
      {
        listingId,
        variationMaybe,
      }
    );
  });

  const cartGroupedByStore = groupBy(newCartData, 'author.id.uuid');
  const newStoreData = storeData.filter(store => cartGroupedByStore[store.author.id.uuid]);

  if (!isAuthenticated) {
    storeCart(newCartProducts);
    return dispatch(
      removeItemToCartDataSuccess({
        newCartData,
        newCartProducts,
        newStoreData
      })
    );
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } })
    .then(() =>
      dispatch(
        removeItemToCartDataSuccess({
          newCartData,
          newCartProducts,
          newStoreData
        })
      )
    )
    .catch(e => {
      console.error(e);
      dispatch(removeItemToCartDataError(storableError(e)));
    });
};

export const updateItemQuantity = ({
  listingId,
  newQuantity,
  variationMaybe,
}) => (dispatch, getState, sdk) => {
  dispatch(updateItemQuantityRequest());

  const { isAuthenticated } = getState().auth;

  const { cartData, cartProducts } = getState().CartPage;

  const newCartProducts = cartProducts.map(item => {
    if (
      JSON.stringify(pick(item, ['listingId', 'variationMaybe'])) ===
      JSON.stringify({ listingId, variationMaybe })
    ) {
      return {
        ...item,
        quantity: newQuantity,
      };
    }
    return item;
  });

  const newCartData = cartData.map(item => {
    const {
      id: { uuid: itemListingId },
      variationMaybe: itemVariationMaybe,
    } = pick(item, ['id.uuid', 'variationMaybe']);
    if (
      JSON.stringify({
        listingId: itemListingId,
        variationMaybe: itemVariationMaybe,
      }) === JSON.stringify({ listingId, variationMaybe })
    ) {
      return {
        ...item,
        quantity: newQuantity,
      };
    }

    return item;
  });

  if (!isAuthenticated) {
    storeCart(newCartProducts);
    return dispatch(
      updateItemQuantitySuccess({
        newCartData,
        newCartProducts,
      })
    );
  }

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } })
    .then(() =>
      dispatch(
        updateItemQuantitySuccess({
          newCartData,
          newCartProducts,
        })
      )
    )
    .catch(e => {
      console.error(e);
      dispatch(updateItemQuantityError(storableError(e)));
    });
};

export const updateCartDataAfterCheckout = (selectedItems = []) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(updateCartDataAfterCheckoutRequest());

  const { cartData, cartProducts } = getState().CartPage;

  const cartItemShouldBeRemoved = selectedItems.map(item => {
    const { listingId, variationValues = '' } = pick(item, [
      'listingId',
      'variationValues',
    ]);
    return JSON.stringify({
      listingId,
      variationValues,
    });
  });

  const newCartProducts = cartProducts.filter(item => {
    const { listingId, variationValues = '' } = pick(item, [
      'listingId',
      'variationValues',
    ]);

    return !cartItemShouldBeRemoved.includes(
      JSON.stringify({
        listingId,
        variationValues,
      })
    );
  });

  const newCartData = cartData.filter(item => {
    const {
      id: { uuid: listingId },
      variationValues = '',
    } = pick(item, ['id.uuid', 'variationValues']);

    return !cartItemShouldBeRemoved.includes(
      JSON.stringify({
        listingId,
        variationValues,
      })
    );
  });

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .updateProfile({ privateData: { cartData: newCartProducts } }, queryParams)
    .then(response => {
      dispatch(
        updateCartDataAfterCheckoutSuccess({ newCartData, newCartProducts })
      );

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error(
          'Expected a resource in the sdk.currentUser.updateProfile response'
        );
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => dispatch(updateCartDataAfterCheckoutError(storableError(e))));
};

const getListingData = (config, { cartListingIds, cartData }) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ids: cartListingIds,
    include: ['author', 'images'],
    'fields.listing': ['title', 'state', 'price', 'publicData'],
    'fields.user': ['profile.publicData', 'profile.metadata'],
    'fields.image': [`variants.${variantPrefix}`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    'limit.images': 1,
  };

  return queryCartListing(JSON.stringify(params)).then(response => {
    if (!isEmpty(response.data.data)) {
      const listings = denormalisedResponseEntities(response);
      const listingsData = cartData.map(item => {
        const { authorId, listingId, ...rest } = item;
        const listingDetails = listings.find(
          listing => listing.id.uuid === item.listingId
        );

        if (!listingDetails) {
          return null;
        }

        return { ...rest, ...listingDetails };
      }).filter(Boolean);

      return sortBy(
        listingsData,
        o => o.attributes.state === LISTING_STATE_CLOSED
      );
    }

    return [];
  })
};

const getStoreListingData = (config, { storeListingIds }) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ids: storeListingIds,
    include: ['author', 'images'],
    'fields.image': [`variants.${variantPrefix}`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings.query(params).then(responses => {
    const storeListings = denormalisedResponseEntities(responses);
    return storeListings;
  });
};

export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  // const { productCategoriesData } = getState().SearchPage;
  dispatch(fetchCartDataRequest());

  let listingDataResponse;
  let newCartData;

  const { isAuthenticated } = getState().auth;

  if (!isAuthenticated) {
    const { cartData = [] } = getCartData(null);
    const cartListingIds = cartData.map(item => item.listingId);
    return dispatch(getListingData(config, { cartListingIds, cartData }))
      .then(listingsData => {
        if (listingsData.length !== cartData.length) {
          const fetchedListingIds = listingsData.map(item => item.id.uuid);
          newCartData = cartData.filter(item =>
            fetchedListingIds.includes(item.listingId)
          );

          storeCart(newCartData);
        }

        listingDataResponse = validateProductInCart(listingsData);
        const storeListingIds = listingsData.map(
          item => item.author.attributes.profile.metadata.storeId
        );

        return dispatch(getStoreListingData(config, { storeListingIds }));
      })
      .then(storeListings => {
        dispatch(
          fetchCartDataSuccess({
            listingData: listingDataResponse,
            storeListings,
            newCartData,
          })
        );
      })
      // .then(() => {
      //   if (_.isEmpty(productCategoriesData)) {
      //     return getProductCategories();
      //   }
      // })
      // .then(productCateoryResponse => {
      //   if (
      //     productCateoryResponse &&
      //     productCateoryResponse.data &&
      //     _.isArray(productCateoryResponse.data)
      //   ) {
      //     const productCategories = transformDataWithLodash(
      //       productCateoryResponse.data
      //     );
      //     return dispatch(fetchProductCategoriesSuccess(productCategories));
      //   }
      // })
      .catch(e => dispatch(fetchCartDataError(storableError(e))));
  }
  return sdk.currentUser
    .show()
    .then(response => {
      const currentUser = denormalisedResponseEntities(response)[0];
      const { cartData = [] } = getCartData(currentUser);
      const cartListingIds = cartData.map(item => item.listingId);
      return dispatch(
        getListingData(config, { cartListingIds, cartData })
      ).then(listingsData => ({ listingsData, cartData }));
    })
    .then(({ listingsData, cartData }) => {
      if (listingsData.length !== cartData.length) {
        const fetchedListingIds = listingsData?.map(item => item.id.uuid);
        newCartData = cartData.filter(item =>
          fetchedListingIds.includes(item.listingId)
        );

        return sdk.currentUser
          .updateProfile({
            privateData: { cartData: newCartData },
          })
          .then(() => listingsData);
      }

      return listingsData;
    })
    .then(listingsData => {
      listingDataResponse = validateProductInCart(listingsData);
      const storeListingIds = listingsData.map(
        item => item.author.attributes.profile.metadata.storeId
      );

      return dispatch(getStoreListingData(config, { storeListingIds }));
    })
    .then(storeListings => {
      dispatch(
        fetchCartDataSuccess({
          listingData: listingDataResponse,
          storeListings,
          newCartData,
        })
      );
    })
    // .then(() => {
    //   if (_.isEmpty(productCategoriesData)) {
    //     return getProductCategories();
    //   }
    // })
    // .then(productCateoryResponse => {
    //   if (
    //     productCateoryResponse &&
    //     productCateoryResponse.data &&
    //     _.isArray(productCateoryResponse.data)
    //   ) {
    //     const productCategories = transformDataWithLodash(
    //       productCateoryResponse.data
    //     );
    //     return dispatch(fetchProductCategoriesSuccess(productCategories));
    //   }
    // })
    .catch(e => dispatch(fetchCartDataError(storableError(e))));
};

const fetchProductCategoriesSuccess = payload => ({
  type: FETCHING_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

const transformDataWithLodash = data => {
  const reponse = _.reduce(
    data,
    (result, record) => {
      const {
        top_level_category,
        top_level_category_label,
        parent_category,
        parent_category_label,
        parent_category_img,
        sub_category,
        sub_category_label,
        sub_category_img,
      } = record.fields;

      const topLevelCategoryKey = 'topLevelCategory';
      const parentCategoryKey = 'parentCategory';
      const productTypeKey = 'productType';
      const childrenOfTopLevelCategoryKey = 'childrenOfTopLevelCategory';
      const childrenOfParentCategoryKey = 'childrenOfParentCategory';

      // Process childrenOfTopLevelCategory
      _.set(
        result,
        [childrenOfTopLevelCategoryKey, top_level_category],
        _.unionBy(
          _.get(
            result,
            [childrenOfTopLevelCategoryKey, top_level_category],
            []
          ),
          [{ key: parent_category, label: parent_category_label }],
          'key'
        )
      );

      // Process childrenOfParentCategory
      const subCategoryItem = sub_category
        ? { key: sub_category, label: sub_category_label }
        : { key: END_OF_PRODUCT_TYPE_SEARCH };

      _.set(
        result,
        [childrenOfParentCategoryKey, parent_category],
        _.unionBy(
          _.get(result, [childrenOfParentCategoryKey, parent_category], []),
          [subCategoryItem],
          'key'
        )
      );

      // Process topLevelCategory
      if (!_.find(result[topLevelCategoryKey], { key: top_level_category })) {
        result[topLevelCategoryKey].push({
          key: top_level_category,
          label: top_level_category_label,
        });
      }

      // Process parentCategory
      if (!_.find(result[parentCategoryKey], { key: parent_category })) {
        result[parentCategoryKey].push({
          key: parent_category,
          label: parent_category_label,
          img: parent_category_img,
          parent: top_level_category,
        });
      }

      // Process productType
      if (!_.find(result[productTypeKey], { key: sub_category })) {
        result[productTypeKey].push({
          key: sub_category,
          label: sub_category_label,
          parent: parent_category,
          img: sub_category_img,
        });
      }

      return result;
    },
    {
      topLevelCategory: [],
      parentCategory: [],
      productType: [],
      childrenOfTopLevelCategory: {},
      childrenOfParentCategory: {},
    }
  );
  return reponse;
};
