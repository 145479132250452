// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../../config/settings';
import { types as sdkTypes, transit } from '../../util/sdkLoader';
import Decimal from 'decimal.js';
import qs from 'qs';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

const handleResponse = res => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader
    ? contentTypeHeader.split(';')[0]
    : null;

  if (res.status >= 400) {
    return res.json().then(data => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  } else if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, {
    typeHandlers,
    verbose: appSettings.sdk.transitVerbose,
  });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

export const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader
      ? contentTypeHeader.split(';')[0]
      : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

export const upload = (path, file, data = {}) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}`;
  const formData = new FormData();
  formData.append('file', file);

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  const options = {
    method: 'POST',
    credentials: 'include',
    body: formData,
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

export const get = (path, query) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}${
    query ? `?${qs.stringify(query, { encodeValuesOnly: true })}` : ''
  }`;

  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

export const put = (path, body) => {
  if (typeof window === 'undefined') {
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};
