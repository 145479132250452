import React from 'react';
import { Link } from 'react-router-dom';

import './ModuleType2.css';

import Module2Image1 from '../../assets/novica-images/modules/module2-image1.png';
import Module2Image2 from '../../assets/novica-images/modules/module2-image2.png';

const ModuleType2 = () => {
  return (
    <section className="about-module-type2">
      <div className="container">
        <div className="text">
          <div className="heading">
            <span className="intro">Our Movement</span>
            <h5>Welcome Home</h5>
          </div>
          <div className="content">
            <p>
              Not long ago, most of us were artisans. Today's creators tether us
              to that forgotten past. Welcome home, we might start saying to
              each other. <em>Welcome Home</em>.
            </p>
            <p>
              <Link
                to="/about-us/the-movement/"
                className="button button-offwhite button-animate button-animate-highlight1"
              >
                Join the Movement
                <span className="icon material-symbols-outlined c-highlight1">
                  arrow_right_alt
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="images">
          <div className="image1">
            <img src={Module2Image1} className="m2-img1" />
          </div>
          <div className="image2">
            <img src={Module2Image2} className="m2-img2" />
          </div>
        </div>
        <div className="content-bottom">
          <p>
            Not long ago, most of us were artisans. Today's creators tether us
            to that forgotten past. Welcome home, we might start saying to each
            other. <em>Welcome Home</em>.
          </p>
          <p>
            <Link
              to="/about-us/the-movement/"
              className="button button-offwhite button-animate button-animate-highlight1"
            >
              Join the Movement
              <span className="icon material-symbols-outlined c-highlight1">
                arrow_right_alt
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ModuleType2;
