import React from 'react';
import loadable from '@loadable/component';
import { loadData as AdminDashboardPageLoader } from './containers/AdminDashboardPage/AdminDashboardPage.duck';

const AdminDashboardPage = loadable(() =>
  import(
    /* webpackChunkName: 'AdminDashboardPage' */ './containers/AdminDashboardPage/AdminDashboardPage'
  )
);

const routes = [
  {
    path: '/admin/dashboard',
    name: 'AdminDashboardPage',
    auth: true,
    component: AdminDashboardPage,
    loadData: AdminDashboardPageLoader,
  },
];

export default routes;
