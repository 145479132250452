import _ from 'lodash';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';
import { fetchSoldListings, getProductCategories } from '../../util/api';
import {
  FETCHING_PRODUCT_CATEGORIES_SUCCESS,
  FETCHING_PRODUCT_CATEGORIES_REQUEST,
  FETCHING_PRODUCT_CATEGORIES_ERROR,
} from '../SearchPage/SearchPage.duck';
import { storableError } from '../../util/errors';
import { END_OF_PRODUCT_TYPE_SEARCH } from '../../util/types';
import { getImageVariants } from '../TransactionPage/TransactionPage.duck';

// ================ Action types ================ //

export const FETCH_TRANSACTION_REQUEST =
  'app/HomePageCustom/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS =
  'app/HomePageCustom/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR =
  'app/HomePageCustom/FETCH_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  fetchTransactionsError: null,
  fetchTransactionsInProgress: false,
};

const homePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        transactions: [],
        fetchTransactionsError: null,
        fetchTransactionsInProgress: true,
      };

    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: payload,
        fetchTransactionsError: null,
        fetchTransactionsInProgress: false,
      };
    case FETCH_TRANSACTION_ERROR:
      return {
        ...state,
        transactions: [],
        fetchTransactionsError: payload,
        fetchTransactionsInProgress: false,
      };

    default:
      return state;
  }
};

export default homePageReducer;

// ================ Action creators ================ //

const fetchProductCategoriesSuccess = payload => ({
  type: FETCHING_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

const fetchProductCategoriesError = e => ({
  type: FETCHING_PRODUCT_CATEGORIES_ERROR,
  error: true,
  payload: e,
});

const fetchProductCategoriesRequest = () => ({
  type: FETCHING_PRODUCT_CATEGORIES_REQUEST,
});

export const fetchTransactionsRequest = () => ({
  type: FETCH_TRANSACTION_REQUEST,
});
export const fetchTransactionsSuccess = payload => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload,
});
export const fetchTransactionsError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const fetchProductCategories = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchProductCategoriesRequest());

  return getProductCategories()
    .then(response => {
      const productCategories = transformDataWithLodash(response.data);
      dispatch(fetchProductCategoriesSuccess(productCategories));
    })
    .catch(e => {
      dispatch(fetchProductCategoriesError(storableError(e)));
    });
};

const transformDataWithLodash = data => {
  const response = _.reduce(
    data,
    (result, record) => {
      const {
        top_level_category,
        top_level_category_label,
        parent_category,
        parent_category_label,
        parent_category_img,
        sub_category,
        sub_category_label,
        sub_category_img,
      } = record.fields;

      const topLevelCategoryKey = 'topLevelCategory';
      const parentCategoryKey = 'parentCategory';
      const productTypeKey = 'productType';
      const childrenOfTopLevelCategoryKey = 'childrenOfTopLevelCategory';
      const childrenOfParentCategoryKey = 'childrenOfParentCategory';

      // Process childrenOfTopLevelCategory
      _.set(
        result,
        [childrenOfTopLevelCategoryKey, top_level_category],
        _.unionBy(
          _.get(
            result,
            [childrenOfTopLevelCategoryKey, top_level_category],
            []
          ),
          [{ key: parent_category, label: parent_category_label }],
          'key'
        )
      );

      // Process childrenOfParentCategory
      const subCategoryItem = sub_category
        ? { key: sub_category, label: sub_category_label }
        : { key: END_OF_PRODUCT_TYPE_SEARCH };

      _.set(
        result,
        [childrenOfParentCategoryKey, parent_category],
        _.unionBy(
          _.get(result, [childrenOfParentCategoryKey, parent_category], []),
          [subCategoryItem],
          'key'
        )
      );

      // Process topLevelCategory
      if (!_.find(result[topLevelCategoryKey], { key: top_level_category })) {
        result[topLevelCategoryKey].push({
          key: top_level_category,
          label: top_level_category_label,
        });
      }

      // Process parentCategory
      if (!_.find(result[parentCategoryKey], { key: parent_category })) {
        result[parentCategoryKey].push({
          key: parent_category,
          label: parent_category_label,
          img: parent_category_img,
          parent: top_level_category,
        });
      }

      // Process productType
      if (!_.find(result[productTypeKey], { key: sub_category })) {
        result[productTypeKey].push({
          key: sub_category,
          label: sub_category_label,
          parent: parent_category,
          img: sub_category_img,
        });
      }

      return result;
    },
    {
      topLevelCategory: [],
      parentCategory: [],
      productType: [],
      childrenOfTopLevelCategory: {},
      childrenOfParentCategory: {},
    }
  );
  return response;
};

export const fetchTransactions = config => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionsRequest());
  fetchSoldListings({
    queryParams: getImageVariants(config.layout.listingImage),
  })
    .then(response => {
      dispatch(fetchTransactionsSuccess(response.data));
    })
    .catch(err => {
      return dispatch(fetchTransactionsError());
    });
};

export const loadData = (params, search, config) => (dispatch, getState) => {
  dispatch(fetchTransactions(config));
  return Promise.resolve();
};
