import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGarbage.module.css';

const IconGarbage = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      />
    </svg>
  );
};

IconGarbage.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconGarbage.propTypes = { rootClassName: string, className: string };

export default IconGarbage;
