import React, { useState } from 'react';
import css from './ShipToModal.module.css';
import { Modal } from '../../../components';

const ShipToModal = ({
  isModalOpen,
  closeModal = () => {},
  onManageDisableScrolling,
}) => {
  return (
    <>
      <Modal
        id="TobarDesktop.ShipToModal"
        isOpen={isModalOpen}
        onClose={closeModal}
        containerClassName={css.container}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.shipToContent}>
          <h5>Your Home for Handmade </h5>
          <p>
            Currently, we're only shipping to the US, but our goal is to deliver
            handmade to your doorstep, wherever you are. We’ll be rolling out new
            country destinations in the months to come. Thank you!
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ShipToModal;
