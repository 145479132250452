import React, { useMemo, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import { types as sdkTypes } from '../../util/sdkLoader';

import css from './FieldCartItemGroup.module.css';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';

import NamedLink from '../NamedLink/NamedLink';
import IconGarbage from '../IconGarbage/IconGarbage';
import { SecondaryButton } from '../Button/Button';
import IconSpinner from '../IconSpinner/IconSpinner';
import { LISTING_STATE_CLOSED } from '../../util/types';
import { FormattedText } from '../../util/data';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { findProductVariationByKey } from '../../util/cart';

const { Money } = sdkTypes;

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  const classes = classNames(css.iconCheckboxRoot, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            className={checkedClassName || css.checked}
            d="M9.9992985 1.5048549l-.0194517 6.9993137C9.977549 9.3309651 9.3066522 10 8.4798526 10H1.5001008c-.8284271 0-1.5-.6715729-1.5-1.5l-.000121-7c0-.8284271.6715728-1.5 1.5-1.5h.000121l6.9993246.0006862c.8284272.000067 1.4999458.671694 1.499879 1.5001211a1.5002208 1.5002208 0 0 1-.0000059.0040476z"
          />
          <path
            className={boxClassName || css.box}
            d="M10.9992947 1.507634l-.0194518 6.9993137C10.9760133 9.8849417 9.8578519 11 8.4798526 11H1.5001008c-1.3807119 0-2.5-1.1192881-2.5-2.4999827L-1.0000202 1.5c0-1.3807119 1.119288-2.5 2.500098-2.5l6.9994284.0006862c1.3807118.0001115 2.4999096 1.11949 2.4997981 2.5002019-.0000018.003373-.0000018.003373-.0000096.0067458z"
          />
        </g>
        <path
          className={css.matterColorLightStroke}
          d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        />
      </g>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const DEBOUNCE_TIMEOUT = 200;

const FieldCartItemGroupComponent = props => {
  const config = useConfiguration();

  const [isRemoveItem, setIsRemoveItem] = useState(false);

  const [currentQuantity, setCurrentQuantity] = useState(props.listingData.quantity);

  // const { currencyRatesData } = useSelector(state => state.SearchPage);

  const intl = useIntl();

  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    listingData,
    useSuccessColor,
    onRemoveItem,
    removeItemInProgress,
    onUpdateItemQuantity,
    prepareItemBeforeCheckoutInProgress,
    ...rest
  } = props;

  const {
    attributes,
    images,
    id: listingId,
    variationMaybe,
    variationValues,
    isProductNotAvailable,
    isOutOfStock,
    isNotEnoughStock,
    author = {}
  } = listingData;
  const {
    title,
    state,
    price,
    publicData,
  } = attributes || {};

  const {
    variationOptions,
    variationKeys = [],
    stock: productDefaultStock,
    defaultPrice: productDefaultPrice,
  } = publicData;

  const isClosed = state === LISTING_STATE_CLOSED;

  const firstImage = images && images.length > 0 ? images[0] : null;

  const variationSelectKey = variationMaybe
    ? variationKeys.map(key => variationMaybe[key]).join('-')
    : null;

  const variationObjectMaybe = variationMaybe
    ? findProductVariationByKey(variationOptions, variationSelectKey)
    : null;

  const { price: variationPrice = 0, stock: variationStock = 0 } = variationObjectMaybe || {};

  const itemPrice = variationMaybe
    ? new Money(variationPrice, config.currency)
    : new Money(productDefaultPrice, config.currency);

  const itemMaximumStock = variationMaybe ? variationStock : productDefaultStock;

  const formattedPrice = formatMoney(intl, itemPrice);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCartItemGroup
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};

  const handleRemoveItem = e => {
    e.preventDefault();
    setIsRemoveItem(true);
    onRemoveItem({ listingId: listingId.uuid, variationMaybe });
  }

  const handleUpdateItemQuantity = newQuantity => {
    onUpdateItemQuantity({
      listingId: listingId.uuid,
      variationMaybe,
      newQuantity,
    });
  }

  const debouncedUpdateItemQuantityHandler = useMemo(
    () => debounce(handleUpdateItemQuantity, DEBOUNCE_TIMEOUT),
    []
  );

  const handleIncreseItemQuantity = e => {
    e.preventDefault();
    if (currentQuantity < itemMaximumStock) {
      setCurrentQuantity(currentQuantity + 1);
      debouncedUpdateItemQuantityHandler(currentQuantity + 1);
    }
  }

  const handleDecreaseItemQuantity = e => {
    e.preventDefault();
    if (currentQuantity === 1) {
      setIsRemoveItem(true);
      setCurrentQuantity(0);
      onRemoveItem({ listingId: listingId.uuid, variationMaybe });
    } else {
      setCurrentQuantity(currentQuantity - 1);
      debouncedUpdateItemQuantityHandler(currentQuantity - 1);
    }
  }

  const productSlug = publicData.productName;

  const params = {
    productSlug,
    id: listingId.uuid,
  };

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <div
        // htmlFor={id}
        className={classNames(css.label, {
          [css.disabledLabel]:
            isClosed ||
            isProductNotAvailable ||
            isOutOfStock ||
            isNotEnoughStock,
        })}
      >
        <div
          className={classNames(css.itemWrapperFormobile, {
            [css.closedListing]:
              isClosed ||
              isProductNotAvailable ||
              isOutOfStock ||
              isNotEnoughStock,
          })}
        >
          {/* <span
            className={classNames(css.checkboxWrapper, {
              [css.hideCheckBox]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
            })}
          >
            <IconCheckbox
              className={svgClassName}
              {...successColorVariantMaybe}
            />
          </span> */}
          <NamedLink
            className={classNames(css.imageWrapper, {
              [css.blurClosedListing]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
            })}
            name="ListingPage"
            params={params}
          >
            <AspectRatioWrapper
              className={css.rootForImageWrapper}
              width={aspectWidth}
              height={aspectHeight}
            >
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
              />
            </AspectRatioWrapper>
          </NamedLink>
          <div
            className={classNames(css.itemDetails, {
              [css.itemDetailsForClosedListing]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
              [css.blurClosedListing]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
            })}
          >
            <NamedLink
              className={css.title}
              name="ListingPage"
              params={params}
            >
              {title}
            </NamedLink>
            {!!variationMaybe && (
              <FormattedText
                id="FieldCartItemGroup.variation"
                tagName="p"
                className={css.variation}
                values={{ value: variationValues }}
              />
            )}
            {isClosed && (
              <FormattedText
                className={css.closedListing}
                id="OrderPanel.closedListingButtonText"
                tagName="p"
              />
            )}
          </div>
          <div className={css.removeButtonInMobile}>
            <SecondaryButton
              type="button"
              className={css.icon}
              onClick={handleRemoveItem}
              disabled={prepareItemBeforeCheckoutInProgress}
            >
              {removeItemInProgress && isRemoveItem ? (
                <IconSpinner />
              ) : (
                <IconGarbage />
              )}
            </SecondaryButton>
          </div>
        </div>

        <div className={css.priceAndRemoveButton}>
          <p
            className={classNames(css.price, {
              [css.closedListing]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
              [css.blurClosedListing]:
                isClosed ||
                isProductNotAvailable ||
                isOutOfStock ||
                isNotEnoughStock,
            })}
          >
            {formattedPrice}
          </p>
          {isProductNotAvailable || isOutOfStock || isNotEnoughStock ? (
            <FormattedText
              className={css.productNotAvailable}
              id={
                isProductNotAvailable
                  ? 'FieldCartItemGroup.productNotAvailableText'
                  : isOutOfStock
                  ? 'FieldCartItemGroup.outOfStockText'
                  : 'FieldCartItemGroup.notEnoughStockText'
              }
              tagName="p"
            />
          ) : (
            <div className={css.quantityFieldChange}>
              <SecondaryButton
                className={css.squareButton}
                type="button"
                onClick={handleDecreaseItemQuantity}
                disabled={isClosed || currentQuantity === 0}
              >
                -
              </SecondaryButton>
              <span
                className={classNames(css.quantity, {
                  [css.blurClosedListing]: isClosed,
                })}
              >
                {currentQuantity}
              </span>
              <SecondaryButton
                className={css.squareButton}
                type="button"
                onClick={handleIncreseItemQuantity}
                disabled={isClosed || currentQuantity >= itemMaximumStock}
              >
                +
              </SecondaryButton>
            </div>
          )}

          <SecondaryButton
            type="button"
            className={classNames(css.icon, css.basketIcon)}
            onClick={handleRemoveItem}
            disabled={prepareItemBeforeCheckoutInProgress}
          >
            {removeItemInProgress && isRemoveItem ? (
              <IconSpinner />
            ) : (
              <IconGarbage />
            )}
          </SecondaryButton>
        </div>
      </div>
    </span>
  );
};

FieldCartItemGroupComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  listingData: null,
  onRemoveItem: () => null,
  removeItemInProgress: false,
  prepareItemBeforeCheckoutInProgress: false,
};

FieldCartItemGroupComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  listingData: object,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,

  onRemoveItem: func,
  removeItemInProgress: bool,
  prepareItemBeforeCheckoutInProgress: bool,
};

export default FieldCartItemGroupComponent;
