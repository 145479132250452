import { storableError } from '../util/errors';
import {
  convertCountryToCurrency,
  getStoredUserSelectedCurrency,
  storeUserSelectedCurrency,
} from '../util/currency';
import { reverseGeocoding } from '../util/mapbox';
import { handleFetchingCurrencyRates } from '../containers/SearchPage/SearchPage.duck';

const DEFAULT_CURRENCY = 'USD';

// ================ Action types ================ //

export const CURRENCY_SELECTED_REQUEST =
  'app/currency/CURRENCY_SELECTED_REQUEST';
export const CURRENCY_SELECTED_SUCCESS =
  'app/currency/CURRENCY_SELECTED_SUCCESS';
export const CURRENCY_SELECTED_ERROR = 'app/currency/CURRENCY_SELECTED_ERROR';

// ================ Reducer ================ //

const initialState = {
  currencySelected: getStoredUserSelectedCurrency() || DEFAULT_CURRENCY,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENCY_SELECTED_REQUEST:
      return state;
    case CURRENCY_SELECTED_SUCCESS:
      return {
        ...state,
        currencySelected: payload,
      };
    case CURRENCY_SELECTED_ERROR:
      return {
        ...state,
        currencySelected: DEFAULT_CURRENCY,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const currencySelectedRequest = () => ({
  type: CURRENCY_SELECTED_REQUEST,
});
export const currencySelectedSuccess = currency => ({
  type: CURRENCY_SELECTED_SUCCESS,
  payload: currency,
});
export const currencySelectedError = error => ({
  type: CURRENCY_SELECTED_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const getCurrencyBasedOnLocation = ({ latitude, longitude }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(currencySelectedRequest());

  // Reverse geocoding using Mapbox Geocoding API
  return reverseGeocoding(latitude, longitude)
    .then(data => {
      const { place_name: countryName } = data.features[0];
      const currency = convertCountryToCurrency(countryName);
      
      storeUserSelectedCurrency(currency);
      dispatch(currencySelectedSuccess(currency));
      dispatch(handleFetchingCurrencyRates());
    })
    .catch(error => {
      console.log('Error occurred during reverse geocoding.');
      storeUserSelectedCurrency();
      dispatch(currencySelectedError(storableError(error)));
      dispatch(handleFetchingCurrencyRates());
    });
};

export const selectCurrency = currency => (dispatch, getState, sdk) => {
  dispatch(currencySelectedRequest());

  storeUserSelectedCurrency(currency);
  dispatch(currencySelectedSuccess(currency));
  dispatch(handleFetchingCurrencyRates());
};
