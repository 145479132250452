import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import { convertMoneyToNumber } from './currency';

// Function to create a SHA-1 hash of an email address using crypto.subtle
export const sha1Hash = async email => {
  // Return an empty string if the email is empty
  // Because SHA1 always returns a 40-character hexadecimal string
  // even if the input is empty ''
  if (isEmpty(email)) return '';

  // Encode the email address as UTF-8
  const encoder = new TextEncoder();
  const data = encoder.encode(email);

  // Create a hash buffer
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);

  // Convert the hash buffer to a hexadecimal string
  const hashedEmail = Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashedEmail;
};

export const prepareDataForOnlineSaleEvent = async (
  transaction,
  listings,
  currentUser
) => {
  if (isEmpty(transaction) || isEmpty(listings) || isEmpty(currentUser))
    return null;

  const txId = transaction.id.uuid;
  // We use finalPayoutToDisplay.amount because it already has the decimal point
  const orderTotalExcludingDiscount =
    transaction.attributes.metadata.finalPayoutToDisplay.amount;
  const customerID = currentUser.id.uuid;
  const customerEmaiSHA1lHash = await sha1Hash(currentUser?.attributes.email);
  const currency = 'USD';

  const orderPromoData = transaction.attributes.metadata.discountCodeData
    ? {
        orderPromoCode: transaction.attributes.metadata.discountCodeData.code,
        orderDiscount:
          transaction.attributes.metadata.taxData.lineItems[0].discount,
      }
    : {};

  const items = listings.map(item => {
    const itemDataInTxMetadata = find(
      transaction.attributes.metadata.quantityAndProductVariation,
      { listingId: item.id.uuid }
    );
    const itemTopCategory = item.attributes.publicData.top_level_category;
    const itemParentCategory = item.attributes.publicData.parent_category;
    const itemProductType = item.attributes.publicData.product_type;
    const itemCategory =
      itemProductType === itemParentCategory
        ? itemTopCategory
        : itemParentCategory;
    return {
      subTotal: convertMoneyToNumber(item.attributes.price) ?? '',
      category: itemCategory,
      sku: item.attributes.publicData.sku ?? '',
      quantity: itemDataInTxMetadata.quantity ?? '',
      name: item.attributes.title ?? '',
    };
  });

  return {
    orderId: txId,
    orderTotalExcludingDiscount,
    customerID: customerID,
    customerEmail: customerEmaiSHA1lHash,
    customerStatus: '',
    currencyCode: currency,
    items,
    ...orderPromoData,
  };
};

export const prepareDataForAddToCartEvent = (listing, itemOption) => {
  if (isEmpty(listing) || isEmpty(itemOption)) return null;
  const currency = 'USD';
  const { quantity: listingQuantity } = itemOption;
  const totalAmount =
    convertMoneyToNumber(listing.attributes.price) * listingQuantity;
  const listingPrice = convertMoneyToNumber(listing.attributes.price);
  const listingTopCategory = listing.attributes.publicData.top_level_category;
  const listingParentCategory = listing.attributes.publicData.parent_category;
  const listingProductType = listing.attributes.publicData.product_type;
  const listingCategory =
    listingProductType === listingParentCategory
      ? listingTopCategory
      : listingParentCategory;
  const listingSku = listing.attributes.publicData.sku;
  const listingName = listing.attributes.title;
  const listingAddedToCartInfo = [
    {
      subTotal: listingPrice,
      category: listingCategory,
      sku: listingSku,
      quantity: listingQuantity,
      name: listingName,
    },
  ];

  return {
    totalAmount,
    currencyCode: currency,
    items: listingAddedToCartInfo,
  };
};

export const prepareDataForViewProductEvent = listing => {
  if (isEmpty(listing)) return {};
  const eventValue = convertMoneyToNumber(listing.attributes.price);
  const currency = 'USD';
  const item_id = get(listing, 'attributes.publicData.sku', '');
  const item_name = get(listing, 'attributes.title', '');
  const item_category = get(
    listing,
    'attributes.publicData.top_level_category',
    ''
  );
  const item_category2 = get(
    listing,
    'attributes.publicData.parent_category',
    ''
  );
  const item_category3 = get(listing, 'attributes.publicData.product_type', '');
  const listingPrice = convertMoneyToNumber(listing.attributes.price);
  return {
    currency,
    value: eventValue,
    items: [
      {
        item_id,
        item_name,
        item_category,
        item_category2,
        item_category3,
        price: listingPrice,
      },
    ],
  };
};
