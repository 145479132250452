import { combineReducers } from 'redux';
import { USER_LOGOUT } from './ducks/auth.duck';
import * as globalReducers from './ducks';
import * as pageReducers from './containers/reducers';
import * as extensionReducers from './extensions/reducers';

const CART_STORED_KEY = `STORED_CART`;

/**
 * Function _createReducer_ combines global reducers (reducers that are used in
 * multiple pages) and reducers that are handling actions happening inside one page container.
 * Since we combineReducers, pageReducers will get page specific key (e.g. SearchPage)
 * which is page specific.
 * Future: this structure could take in asyncReducers, which are changed when you navigate pages.
 */
const appReducer = combineReducers({
  ...globalReducers,
  ...pageReducers,
  ...extensionReducers,
});

const createReducer = () => {
  return (state, action) => {
    const appState = action.type === USER_LOGOUT ? undefined : state;

    // Clear sessionStorage and localStorage when logging out.
    if (
      action.type === USER_LOGOUT &&
      typeof window !== 'undefined' &&
      !!window.sessionStorage &&
      !!window.localStorage
    ) {
      window.sessionStorage.clear();
      window.localStorage.removeItem(CART_STORED_KEY);
    }

    return appReducer(appState, action);
  };
};

export default createReducer;
