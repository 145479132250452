import React from 'react';
import { Link } from 'react-router-dom';

import './ModuleType5.css';

import Module5Image1 from '../../assets/novica-images/modules/module5-image1.png';
import Module5Image2 from '../../assets/novica-images/modules/module5-image2.png';
import Module5Image3 from '../../assets/novica-images/modules/module5-image3.png';
import Module5Image4 from '../../assets/novica-images/modules/module5-image4.png';

const ModuleType5 = () => {
  return (
    <section className="about-module-type5">
      <div className="container">
        <div className="text">
          <div className="heading">
            <span className="intro">Our Commitment</span>
            <h5>
              Stewards of <span className="c-highlight3">Handmade</span>.
            </h5>
          </div>
          <div className="content">
            <p>
              We are committed to ethically-crafted, environmentally-conscious,
              people-first practices. And we strive for authenticity and
              transparency to ensure that handmade crafts live to see another
              day.
            </p>
            <p>
              <Link
                to="/about-us/our-commitment/"
                className="button button-offwhite button-animate button-animate-highlight1"
              >
                Read our Commitment
                <span className="icon material-symbols-outlined c-highlight1">
                  arrow_right_alt
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="images">
          <div className="imagecol1">
            <div className="image1">
              <img src={Module5Image1} className="m5-img1" />
            </div>
          </div>
          <div className="imagecol2">
            <div className="imagerow1">
              <div className="image2">
                <img src={Module5Image2} className="m5-img2" />
              </div>
            </div>
            <div className="imagerow2">
              <div className="image3">
                <img src={Module5Image3} className="m5-img3" />
              </div>
              <div className="image4">
                <img src={Module5Image4} className="m5-img4" />
              </div>
            </div>
          </div>
        </div>
        <div className="content-bottom">
          <p>
            We are committed to ethically-crafted, environmentally-conscious,
            people-first practices. And we strive for authenticity and
            transparency to ensure that handmade crafts live to see another day.
          </p>
          <p>
            <Link
              to="/about-us/our-commitment/"
              className="button button-offwhite button-animate button-animate-highlight1"
            >
              Read our Commitment
              <span className="icon material-symbols-outlined c-highlight1">
                arrow_right_alt
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ModuleType5;
