// ================ Action types ================ //

import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { listRecords } from '../../util/airtableHelpers/list';
import { generateImportingTemplate, uploadFile } from '../../util/api';
import { storableError } from '../../util/errors';
import { FILE_STATE, PRODUCT_TYPE } from '../../util/types';

export const SET_INITIAL_VALUES = 'app/ImportListingsPage/SET_INITIAL_VALUES';

export const FETCH_PRODUCT_TYPES_SUCCESS =
  'app/ImportListingsPage/FETCH_PRODUCT_TYPES_SUCCESS';

export const UPLOAD_FILE_REQUEST = 'app/ImportListingsPage/UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'app/ImportListingsPage/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'app/ImportListingsPage/UPLOAD_FILE_ERROR';

export const GENERATE_TEMPLATE_REQUEST =
  'app/ImportListingsPage/GENERATE_TEMPLATE_REQUEST';
export const GENERATE_TEMPLATE_SUCCESS =
  'app/ImportListingsPage/GENERATE_TEMPLATE_SUCCESS';
export const GENERATE_TEMPLATE_ERROR =
  'app/ImportListingsPage/GENERATE_TEMPLATE_ERROR';

export const RESET_TEMPLATE_GENERATED =
  'app/ImportListingsPage/RESET_TEMPLATE_GENERATED';

// ================ Reducer ================ //

const initialState = {
  uploadedFile: null,
  productTypes: [],
  productTypeSelected: [],
  generateTemplateInProgress: false,
  generateTemplateError: null,
  templateGenerated: null,
};

export default function ImportListingsPageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_PRODUCT_TYPES_SUCCESS:
      return { ...state, productTypes: payload };

    case UPLOAD_FILE_REQUEST:
    case UPLOAD_FILE_ERROR:
    case UPLOAD_FILE_SUCCESS: {
      // payload: { key: 'tempId', filename: 'filename', state: 'file-state', error: null }
      return {
        ...state,
        uploadedFile: {
          ...state.uploadedFile,
          ...payload,
        },
      };
    }

    case GENERATE_TEMPLATE_REQUEST:
      return {
        ...state,
        generateTemplateInProgress: true,
        generateTemplateError: null,
        templateGenerated: null,
        productTypeSelected: [],
      };
    case GENERATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        generateTemplateInProgress: false,
        templateGenerated: payload.templateGenerated,
        productTypeSelected: payload.productTypeSelected,
      };
    case GENERATE_TEMPLATE_ERROR:
      return {
        ...state,
        generateTemplateInProgress: false,
        generateTemplateError: payload,
      };

    case RESET_TEMPLATE_GENERATED:
      return {
        ...state,
        templateGenerated: null,
        productTypeSelected: [],
        generateTemplateError: null,
        generateTemplateInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchProductTypesSuccess = payload => ({
  type: FETCH_PRODUCT_TYPES_SUCCESS,
  payload,
});

const uploadFileRequest = payload => ({
  type: UPLOAD_FILE_REQUEST,
  payload,
});
const uploadFileSuccess = payload => ({
  type: UPLOAD_FILE_SUCCESS,
  payload,
});
const uploadFileError = payload => ({
  type: UPLOAD_FILE_ERROR,
  payload,
});

const generateTemplateRequest = payload => ({
  type: GENERATE_TEMPLATE_REQUEST,
  payload,
});
const generateTemplateSuccess = payload => ({
  type: GENERATE_TEMPLATE_SUCCESS,
  payload,
});
const generateTemplateError = error => ({
  type: GENERATE_TEMPLATE_ERROR,
  error: true,
  payload: error,
});

const resetTemplateGenerated = () => ({
  type: RESET_TEMPLATE_GENERATED,
});

// ================ Thunks ================ //

export const requestFileUpload = actionPayload => (dispatch, getState, sdk) => {
  dispatch(
    uploadFileRequest({
      state: FILE_STATE.IN_PROGRESS,
      error: null,
    })
  );

  return uploadFile(actionPayload?.file)
    .then(response => {
      if (response.status === 200) {
        dispatch(
          uploadFileSuccess({
            state: FILE_STATE.UPLOAD_SUCCESSFULLY,
            error: null,
          })
        );
      }
    })
    .catch(e => {
      console.error(e);
      dispatch(
        uploadFileError({
          state: FILE_STATE.UPLOAD_FAILED,
          error: e,
        })
      );
    });
};

export const generateTemplateFile = values => dispatch => {
  dispatch(generateTemplateRequest());

  return generateImportingTemplate(values)
    .then(response => {
      if (response.status === 200) {
        dispatch(
          generateTemplateSuccess({
            templateGenerated: response.data,
            productTypeSelected: values.productTypes,
          })
        );
      }
    })
    .catch(e => dispatch(generateTemplateError(storableError(e))));
};

export const removeTemplateGeneratedFile = () => dispatch => {
  dispatch(resetTemplateGenerated());
};

export const loadData = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const [currentUser, productAttributesList] = await Promise.all([
      dispatch(fetchCurrentUser()),
      listRecords(),
    ]);

    if (currentUser) {
      dispatch(fetchStripeAccount());
    }

    const productTypeData = productAttributesList.find(
      item => item.name === PRODUCT_TYPE
    );

    if (productTypeData) {
      const productTypes = productTypeData.values.map(item => {
        const [key, value] = item.split('_');
        return { key, label: value };
      });

      const sortedProductTypesByAlphabet = productTypes.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      
      dispatch(fetchProductTypesSuccess(sortedProductTypesByAlphabet));
    }
  } catch (e) {
    console.error(e);
  }
};
