import React, { useState } from 'react';
import { number, string, shape } from 'prop-types';
import { propTypes } from '../../util/types';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { 
  NamedLink, 
  NamedRedirect,
} from '../../components';

import { withViewport } from '../../util/uiHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';

import css from './HorizontalStepper.module.css';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 767;

const HorizontalStepper = (props) => {
  const {
    rootClassName,
    className,
    steps,
    viewport,
  } = props;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasAlternativeLabel =
    hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

  const classes = classNames(rootClassName, className) || css.root;

  const history = useHistory();
  const routes = useRouteConfiguration();

  const handleClick = (linkProps) => {
    history.push(
      createResourceLocatorString(
        linkProps.name, 
        routes, 
        linkProps.params || {}, 
        {}
      )
    );
  };

  return (
    <Box sx={{ width: '100%' }} className={classes}>
      <Stepper alternativeLabel={hasAlternativeLabel}>
        {steps.map((step) => (
          <Step key={step.label} completed={step.completed} active={step.active}>
            {step.active && step.linkProps ? (
              <StepLabel className={classNames(css.steplabel, css.active)} onClick={() => handleClick(step.linkProps)}>
                <NamedLink
                  {...step.linkProps}
                >
                  { step.label }
                </NamedLink>
              </StepLabel>
            ) : (
              <StepLabel className={css.steplabel}>
                {step.label }
              </StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

HorizontalStepper.defaultProps = {
  rootClassName: null,
  className: null,
  steps: null,
};

HorizontalStepper.propTypes = {
  rootClassName: string,
  className: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(HorizontalStepper);