import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';
import Modal from '../Modal/Modal';
import TypeFormWidget from '../TypeFormWidget/TypeFormWidget';

import css from './ModalSellerEthicalPledge.module.css';

const ETHICAL_PLEDGE_MODAL_PAGE_LIST = [
  'ImportListingsPage',
  'StripePayoutPage',
  'EditListingPage',
  'SellerOnboardingPage',
  'ManageListingsPage',
  'SellerDashboardPage',
  'SellerEthicalPledgePage',
];

const defaultIframeProps = {
  class: css.iframe,
};

const defaultStyleProps = {
  borderRadius: '2rem',
  height: '100%',
};

const ModalSellerEthicalPledge = props => {
  const {
    onManageDisableScrolling,
    onSubmitSellerAgreeToEthicalPledge,
    typeFormId,
    currentPage,
    openSellerEthicalPledge,
  } = props;

  const [showSellerEthicalPledge, setShowSellerEthicalPledge] = useState(false);
  const [hasAgreedToEthicalPledge, setHasAgreedToEthicalPledge] = useState(
    false
  );

  const { currentUser } = useSelector(state => state.user);
  useEffect(() => {
    const user = ensureCurrentUser(currentUser);

    const isCurrentPageRequired = ETHICAL_PLEDGE_MODAL_PAGE_LIST.includes(
      currentPage
    );
    const notAgreedYet = !showSellerEthicalPledge && !hasAgreedToEthicalPledge;
    if (currentUser && isCurrentPageRequired && notAgreedYet) {
      const { isSellerAgreeWithEthicalPledge } =
        user.attributes.profile?.metadata || {};
      if (!isSellerAgreeWithEthicalPledge) {
        setShowSellerEthicalPledge(true);
      }
    }
  }, [currentUser]);

  const handleSubmitSellerAgreeToEthicalPledge = useCallback(async () => {
    await onSubmitSellerAgreeToEthicalPledge();
    setHasAgreedToEthicalPledge(true);
  }, []);

  return (
    <Modal
      id="ModalSellerEthicalPledge"
      containerClassName={css.typeFormModal}
      scrollLayerClassName={css.typeFormScrollLayer}
      closeButtonClassName={css.typeFormClose}
      isOpen={showSellerEthicalPledge || openSellerEthicalPledge}
      onClose={() => {
        setShowSellerEthicalPledge(false);
      }}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      showCloseMessage={false}
      isAbleToClose={false}
    >
      <TypeFormWidget
        id={typeFormId}
        className={css.modal}
        height="100%"
        style={defaultStyleProps}
        iframeProps={defaultIframeProps}
        onSubmit={handleSubmitSellerAgreeToEthicalPledge}
      />
    </Modal>
  );
};

export default ModalSellerEthicalPledge;
