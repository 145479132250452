import {
  types as sdkTypes,
} from '../../util/sdkLoader';
import { denormalisedResponseEntities, getImageVariantInfo } from '../../util/data';
import { storableError } from '../../util/errors';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import {
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

import {
  JH_LISTING_TYPES,
} from '../../util/types';
import { listRecords } from '../../util/airtableHelpers/list';

const { UUID } = sdkTypes;

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});

const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => payload => ({
  type: actionType,
  payload,
  error: true,
});

// ================ Action types ================ //

export const SHOW_LISTINGS_REQUEST =
  'app/SellerOnboardingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS =
  'app/SellerOnboardingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR =
  'app/SellerOnboardingPage/SHOW_LISTINGS_ERROR';

export const FETCH_PRODUCT_ATTRIBUTES_SUCCESS =
  'app/SellerOnboardingPage/FETCH_PRODUCT_ATTRIBUTES_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  listingId: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  setStockError: null,
  setStockInProgress: false,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  uploadedImages: {},
  uploadedImagesOrder: [],
  removedImageIds: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  weeklyExceptionQueries: {
    // '2022-12-12': { // Note: id/key is the start of the week in given time zone
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  monthlyExceptionQueries: {
    // '2022-12': {
    //   fetchExceptionsError: null,
    //   fetchExceptionsInProgress: null,
    // },
  },
  allExceptions: [],
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  independentFields: [],
  productAttributes: [],

  isRedirectToManageListingPage: false,

  // To check product name unique
  isStoreNameUnique: true,
  checkUniqueStoreNameInProgress: false,
  checkUniqueStoreNameError: null,

  isCreateSellerOnboardingSuccessfully: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTINGS_REQUEST:
      return {
        ...state,
        showListingsError: null,
        independentFields: [],
        productAttributes: [],
        isCreateSellerOnboardingSuccessfully: false,
      };
    case SHOW_LISTINGS_SUCCESS: {
      const listingIdFromPayload = payload.data.id;
      const {
        listingId,
        allExceptions,
        weeklyExceptionQueries,
        monthlyExceptionQueries,
      } = state;
      // If listing stays the same, we trust previously fetched exception data.
      return listingIdFromPayload?.uuid === state.listingId?.uuid
        ? {
            ...initialState,
            listingId,
            allExceptions,
            weeklyExceptionQueries,
            monthlyExceptionQueries,
          }
        : { ...initialState, listingId: listingIdFromPayload };
    }
    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        showListingsError: payload,
        redirectToListing: false,
        independentFields: [],
        productAttributes: [],
      };

    case FETCH_PRODUCT_ATTRIBUTES_SUCCESS:
      const { independentFields, productAttributes } = payload;
      return { ...state, independentFields, productAttributes };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// SDK method: ownListings.show
export const showListingsRequest = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

export const fetchProductAttributesSuccess = successAction(
  FETCH_PRODUCT_ATTRIBUTES_SUCCESS
);
// ================ Thunk ================ //

export function requestShowListing(actionPayload, config) {
  return (dispatch, getState, sdk) => {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(showListingsRequest(actionPayload));
    return sdk.ownListings
      .show({ ...actionPayload, ...queryParams })
      .then(response => {
        // SellerOnboardingPage fetches new listing data, which also needs to be added to global data
        dispatch(addMarketplaceEntities(response));
        // In case of success, we'll clear state.SellerOnboardingPage (user will be redirected away)
        dispatch(showListingsSuccess(response));
        return response;
      })
      .then(async response => {
        const [listingData] = denormalisedResponseEntities(response);
        if (
          listingData.attributes.publicData?.listingType ===
          JH_LISTING_TYPES.PRODUCT_LISTING
        ) {
          const productAttributesList = await listRecords();
          dispatch(
            fetchProductAttributesSuccess({
              data: {
                independentFields: productAttributesList.filter(
                  record => !record?.depend_on
                ),
                productAttributes: productAttributesList.filter(
                  record => record?.depend_on
                ),
              },
            })
          );
          
          return response;
        }
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params, search, config) => (
  dispatch,
  getState,
  sdk
) => {

  return Promise.all([
    dispatch(fetchCurrentUser()),
  ])
    .then(response => {
      const currentUser = getState().user.currentUser;
      const userListingId = currentUser.attributes.profile.publicData.userListingId;

      if(userListingId) {
        const payload = { id: new UUID(userListingId) };
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return dispatch(requestShowListing(payload, config));
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};