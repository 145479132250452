import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import { Field } from 'react-final-form';
import { statesUSLower48 } from '../../config/configStates';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import css from './FieldStateSelect.module.css';

const FieldStateSelectComponent = props => {
  const {
    rootClassName,
    className,
    labelClassName,
    customErrorText,
    id,
    label,
    allOptionLabel,
    selectOptionLabel,
    searchPlaceholder,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    placeholder,
    selectMenuClassName,
    selectOptions,
    setAvailableStates,
    ...rest
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(input.value || []);
  const [searchValue, setSearchValue] = useState('');
  const [availableOptions, setAvailableOptions] = useState(selectOptions);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  useEffect(() => {
    const select48LowerStates = some(input.value, { "key": "lowerstates" });
    if(select48LowerStates) {
      handleSelect48LowerStates();
    } else {
      setSelectedOptions(input.value || []);
    }
  }, [input.value])

  const { valid, invalid, touched, error } = meta;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const classes = classNames(rootClassName || css.root, className);

  const handleRemoveOption = o => e => {
    if (find(selectedOptions, o)) {
      const filteredArray = selectedOptions.filter(item => item.key !== o.key);
      setSelectedOptions(filteredArray);
      handleChangeInput(!isEmpty(filteredArray) ? filteredArray : null)();
    }
  };

  const handleSelectOption = o => e => {
    if (!find(selectedOptions, o.key)) {
      const newSelectedOptions = [...selectedOptions, o];
      setSelectedOptions(newSelectedOptions);
      handleChangeInput(newSelectedOptions)();
    }
  };

  const exactSameComparator = (a, b) => a.key === b.key;

  const handleSelect48LowerStates = () => {
    const newSelectedOptions = [...statesUSLower48];
    setSelectedOptions(newSelectedOptions);
    handleChangeInput(newSelectedOptions)();
  }

  const handleChangeInput = value => e => {
    input.onChange(value);
  };

  const show48LowerStatesOption = !statesUSLower48.some(obj1 =>
    selectedOptions.some(obj2 => isEqual(obj1, obj2))
  );

  const allStateOptions = [];
  if(show48LowerStatesOption && allOptionLabel && !isEmpty(availableOptions)) {
    allStateOptions.push(
      {
        "key": "lowerstates",
        "label": allOptionLabel
      }
    );
  }
  if(selectOptionLabel && show48LowerStatesOption) {
    allStateOptions.push(
      {
        "key": "noselectoption",
        "label": selectOptionLabel
      }
    );
  }
  if(!isEmpty(availableOptions)) {
    allStateOptions.push(
      ...availableOptions
    );
  }

  return (
    <Autocomplete
      multiple
      options={allStateOptions}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={(option) => option.key === 'noselectoption'}
      value={selectedOptions}
      onChange={(_, value) => {
        input.onChange(value);
        if (value && value.length === 0) {
          input.onBlur();
        }
      }}
      filterSelectedOptions
      renderTags={(value, getTagProps) =>
        value.map((option, index) => 
          option.key !== 'lowerstates' ? (
            <Chip variant="filled" label={option.label} {...getTagProps({ index })} />
          ) : null
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={
            isEmpty(selectedOptions) ? placeholder : searchPlaceholder
          }
          variant="outlined"
          error={hasError}
          helperText={hasError ? errorText : null}
          {...rest}
        />
      )}
    />
  );
};

FieldStateSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  labelClassName: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  allOptionLabel: null,
  selectOptionLabel: null,
  isUncontrolled: false,
  placeholder: null,
  searchPlaceholder: null,
  selectOptions: [],
  selectMenuClassName: null,
};

const { string, object, array, func, shape, bool } = PropTypes;

FieldStateSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  labelClassName: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  allOptionLabel: string,
  selectOptionLabel: string,
  searchPlaceholder: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  placeholder: string,
  selectOptions: array,
  selectMenuClassName: string,
};

const FieldStateSelect = props => {
  return <Field component={FieldStateSelectComponent} {...props} />;
};

export default FieldStateSelect;
