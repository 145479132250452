import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  prepareDataForAddToCartEvent,
  prepareDataForOnlineSaleEvent,
  sha1Hash,
  prepareDataForViewProductEvent,
} from './dataLayer.helpers';
import { DATA_LAYER_EVENTS } from './types';

export const IdentityDataLayer = () => {
  const { currentUser } = useSelector(state => state.user);
  const customerID = currentUser?.id.uuid || '';
  const customerEmail = currentUser?.attributes.email || '';

  const sendCustomerDataToDataLayer = async () => {
    if (window.dataLayer) {
      const hashedEmail = await sha1Hash(customerEmail);
      window.dataLayer.push({
        customerID: customerID,
        customerEmail: hashedEmail,
        event: DATA_LAYER_EVENTS.CUSTOMER_IDENTIFICATION,
      });
    }
  };

  useEffect(() => {
    sendCustomerDataToDataLayer();
  }, [customerID, customerEmail]);

  return null;
};

export const pushOnlineSaleDataToDataLayer = async (
  transaction,
  listings,
  currentUser
) => {
  if (window.dataLayer) {
    const transactionData = await prepareDataForOnlineSaleEvent(
      transaction,
      listings,
      currentUser
    );
    if (transactionData) {
      window.dataLayer.push({
        event: DATA_LAYER_EVENTS.ONLINE_SALE,
        ...transactionData,
      });
    }
  }
  return null;
};

export const pushAddToCartDataToDataLayer = (listing, itemOption) => {
  if (window.dataLayer) {
    const listingAddedToCartData = prepareDataForAddToCartEvent(
      listing,
      itemOption
    );
    window.dataLayer.push({
      event: DATA_LAYER_EVENTS.ADD_TO_CART,
      ...listingAddedToCartData,
    });
  }
  return null;
};

export const pushViewProductDataToDataLayer = listing => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const viewProductData = prepareDataForViewProductEvent(listing);
    window.dataLayer.push({
      event: DATA_LAYER_EVENTS.VIEW_PRODUCT,
      ...viewProductData,
    });
  }

  return null;
};
