// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export function abbreviateName(words) {
  return words
    .replace(/\b(\w)\w+/g, '$1')
    .replace(/\s/g, '')
    .replace(/\.$/, '')
    .toUpperCase();
}
