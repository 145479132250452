import moment from 'moment';
import * as log from '../../util/log';
import { storableError } from '../../util/errors';
import {
  isUserAllowedToAccessDashboard,
  prepareParamsForAPIRequest,
  prepareTimeRangeTimestamps,
} from './SellerDashboardPage.helpers';
import { compareTypes } from './constants';
import { getStatisticsSellerDashboard } from '../../util/api';
// import { sampleResponse } from './mockData';

// ================ Action types ================ //
export const GET_STATISTICS_REQUEST =
  'app/SellerDashboardPage/GET_STATISTICS_REQUEST';
export const GET_STATISTICS_SUCCESS =
  'app/SellerDashboardPage/GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_ERROR =
  'app/SellerDashboardPage/GET_STATISTICS_ERROR';

// ================ Reducer ================ //
const initialState = {
  statistics: null,
  getStatisticsError: null,
  getStatisticsInProgress: false,
  startDate: null,
  endDate: null,
  compareStartDate: null,
  compareEndDate: null,
  comparisonType: compareTypes[0].key,
};

const SellerDashboardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATISTICS_REQUEST:
      let newDatesMaybe = {};
      if (payload.startDate && payload.endDate) {
        newDatesMaybe = {
          startDate: payload.startDate,
          endDate: payload.endDate,
        };
      }
      if (payload.compareStartDate && payload.compareEndDate) {
        newDatesMaybe = {
          ...newDatesMaybe,
          compareStartDate: payload.compareStartDate,
          compareEndDate: payload.compareEndDate,
        };
      } else {
        newDatesMaybe = {
          ...newDatesMaybe,
          compareStartDate: null,
          compareEndDate: null,
        };
      }
      if (payload.comparisonType) {
        newDatesMaybe = {
          ...newDatesMaybe,
          comparisonType: payload.comparisonType,
        };
      }
      return {
        ...state,
        ...newDatesMaybe,
        getStatisticsInProgress: true,
        getStatisticsError: null,
      };
    case GET_STATISTICS_SUCCESS:
      return { ...state, statistics: payload, getStatisticsInProgress: false };
    case GET_STATISTICS_ERROR:
      return {
        ...state,
        getStatisticsError: payload,
        getStatisticsInProgress: false,
      };
    default:
      return state;
  }
};

export default SellerDashboardPageReducer;

// ================ Action creators ================ //
export const getStatisticsRequest = payload => ({
  type: GET_STATISTICS_REQUEST,
  payload,
});
export const getStatisticsSuccess = payload => ({
  type: GET_STATISTICS_SUCCESS,
  payload,
});
export const getStatisticsError = e => ({
  type: GET_STATISTICS_ERROR,
  payload: e,
});
export const returnNotFound = () => ({ type: RETURN_NOT_FOUND });

// ================ Thunks ================ //

const fetchStatistics = async ({ start, end, compareStart, compareEnd }) => {
  try {
    const params = prepareParamsForAPIRequest({
      startDate: start,
      endDate: end,
      compareStartDate: compareStart,
      compareEndDate: compareEnd,
    });
    const response = await getStatisticsSellerDashboard(params);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const onSelectTimeRange = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  comparisonType,
}) => async (dispatch, getState, sdk) => {
  try {
    dispatch(
      getStatisticsRequest({
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        comparisonType,
      })
    );
    const statistics = await fetchStatistics({
      start: startDate,
      end: endDate,
      compareStart: compareStartDate,
      compareEnd: compareEndDate,
    });
    dispatch(getStatisticsSuccess(statistics));
  } catch (e) {
    log.error(e, 'fetch-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
  }
};

export const reloadSellerStatistics = () => async (dispatch, getState, sdk) => {
  try {
    const {
      startDate,
      compareStartDate,
      compareEndDate,
      comparisonType,
    } = getState().SellerDashboardPage;

    const endDate = moment()
      .utc()
      .format();

    const params = prepareTimeRangeTimestamps({
      startDate,
      endDate,
      hasComparison: !!(compareStartDate && compareEndDate),
      comparisonType,
    });

    dispatch(getStatisticsRequest(params));
    const statistics = await fetchStatistics({
      start: startDate,
      end: endDate,
      compareStart: compareStartDate,
      compareEnd: compareEndDate,
    });
    dispatch(getStatisticsSuccess(statistics));
  } catch (e) {
    log.error(e, 'reload-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
  }
};

export const loadData = (params, search, config) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const startDate = moment()
      .subtract(30, 'days')
      .startOf('day')
      .utc()
      .format();
    const endDate = moment()
      .utc()
      .format();
    const currentUser = getState().user.currentUser;
    if (!isUserAllowedToAccessDashboard(currentUser)) {
      return dispatch(getStatisticsError('user-not-allowed'));
    }
    dispatch(getStatisticsRequest({ startDate, endDate }));
    const statistics = await fetchStatistics({
      start: startDate,
      end: endDate,
    });
    dispatch(getStatisticsSuccess(statistics));
  } catch (e) {
    log.error(e, 'fetch-default-statistics-failed');
    dispatch(getStatisticsError(storableError(e)));
  }
};
