import moment from 'moment';
import { SELLER_TYPE } from '../../util/types';
import { unitDivisor } from '../../util/currency';
import { FormattedText } from '../../util/data';

export const DATE_RANGE_FORMAT = 'Do MMM YY';
const CHART_DATE_FORMAT = 'DD/MM';
const CHART_DATE_FORMAT_FROM_API = 'YYYY-MM-DDTHH:mm:ssZ';

import css from './SellerDashboardPage.module.css';

export const setDateRangeLastXDays = (days, form, event) => {
  if (!days || !form || !event) return;
  event.preventDefault();
  event.stopPropagation();
  const today = moment();
  const daysAgo = moment()
    .subtract(days, 'days')
    .startOf('day');

  form.change('dateRange', {
    startDate: daysAgo.toDate(),
    endDate: today.toDate(),
  });
};

export const setDateRangeToday = (form, event) => {
  if (!form || !event) return;
  event.preventDefault();
  event.stopPropagation();
  const today = moment();
  form.change('dateRange', {
    startDate: today.startOf('day').toDate(),
    endDate: today.toDate(),
  });
};

export const isUserAllowedToAccessDashboard = user => {
  if (!user) return true;
  const { metadata } = user.attributes.profile;
  const { hasFinishedOnboarding, isApprovedSeller, sellerType } = metadata;
  return (
    hasFinishedOnboarding &&
    isApprovedSeller &&
    Object.values(SELLER_TYPE).includes(sellerType)
  );
};

const roundNumberToTwoDecimalPlaces = number => {
  if (typeof number !== 'number' || number % 1 === 0) return number;
  return number.toFixed(2);
};

export const prepareDataForDashboard = statistics => {
  if (!statistics) return null;

  const {
    unFulfilledOrders,
    liveProducts,
    totalOrders,
    revenue,
    revenueChartData,
    productSales,
  } = statistics;

  const revenueChartDataFormatted = revenueChartData.map(data => {
    const formattedData = {
      period: moment(data.period, CHART_DATE_FORMAT_FROM_API).format(
        CHART_DATE_FORMAT
      ),
    };
    if (Number.isInteger(data.value)) {
      formattedData.value = data.value / unitDivisor('USD');
    }
    if (Number.isInteger(data.compareValue)) {
      formattedData.compareValue = data.compareValue / unitDivisor('USD');
    }
    return formattedData;
  });

  const liveProductsObject = {
    ...liveProducts,
    label: <FormattedText tagName="p" id="SellerDashboardPage.liveProducts" />,
    percentageChange: roundNumberToTwoDecimalPlaces(
      liveProducts.percentageChange
    ),
  };

  const unFulfilledOrdersObject = {
    ...unFulfilledOrders,
    label: (
      <FormattedText tagName="p" id="SellerDashboardPage.unfulfilledOrders" />
    ),
    percentageChange: roundNumberToTwoDecimalPlaces(
      unFulfilledOrders.percentageChange
    ),
  };

  const totalOrdersObject = {
    label: <FormattedText tagName="p" id="SellerDashboardPage.totalOrders" />,
    ...totalOrders,
    percentageChange: roundNumberToTwoDecimalPlaces(
      totalOrders.percentageChange
    ),
  };

  const revenueObject = {
    ...revenue,
    label: (
      <FormattedText
        tagName="p"
        id="SellerDashboardPage.totalRevenue"
        values={{
          lineBreak: <br />,
          secondLine: (
            <FormattedText
              className={css.labelSecondLine}
              id="SellerDashboardPage.forShippedOrders"
            />
          ),
        }}
      />
    ),
    currency: 'USD',
    percentageChange: roundNumberToTwoDecimalPlaces(revenue.percentageChange),
  };

  const productSalesFormatted = productSales.map(product => {
    const saleRevenue = product.saleRevenue / unitDivisor('USD');
    return {
      ...product,
      saleRevenue,
    };
  });

  return {
    unFulfilledOrders: unFulfilledOrdersObject,
    liveProducts: liveProductsObject,
    totalOrders: totalOrdersObject,
    revenue: revenueObject,
    revenueChartData: revenueChartDataFormatted,
    productSales: productSalesFormatted,
  };
};

export const prepareParamsForAPIRequest = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
}) => {
  const formattedParams = {};

  if (startDate && endDate) {
    formattedParams.timeRange = `${startDate},${endDate}`;
  }

  if (compareStartDate && compareEndDate) {
    formattedParams.compareTimeRange = `${compareStartDate},${compareEndDate}`;
  }

  return formattedParams;
};

// Prepare timestamps to call the seller dashboard statistics API
export const prepareTimeRangeTimestamps = ({
  startDate,
  endDate,
  hasComparison,
  comparisonType,
}) => {
  const startTimestamp = moment(startDate)
    .startOf('day')
    .utc()
    .format();
  // if endTimeStamp is also the same date on the local device, we should use the current time
  // otherwise we should use the end of the day
  const endTimestamp = moment(endDate).isSame(moment(), 'day')
    ? moment()
        .utc()
        .format()
    : moment(endDate)
        .endOf('day')
        .utc()
        .format();

  const comparisonDateGenerator = {
    previousPeriod: () => {
      const duration = moment(endTimestamp).diff(moment(startTimestamp));
      return {
        compareStartDate: moment(startTimestamp)
          .subtract(duration, 'milliseconds')
          .startOf('day')
          .utc()
          .format(),
        compareEndDate: moment(startTimestamp)
          .subtract(1, 'days')
          .endOf('day')
          .utc()
          .format(),
      };
    },
    samePeriodLastYear: () => {
      return {
        compareStartDate: moment(startTimestamp)
          .subtract(1, 'year')
          .startOf('day')
          .utc()
          .format(),
        compareEndDate: moment(endTimestamp)
          .subtract(1, 'year')
          .endOf('day')
          .utc()
          .format(),
      };
    },
  };

  const comparisonDate = hasComparison
    ? comparisonDateGenerator[comparisonType]()
    : null;

  return {
    startDate: startTimestamp,
    endDate: endTimestamp,
    ...comparisonDate,
    comparisonType,
  };
};
