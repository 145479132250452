import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import ValidationError from '../ValidationError/ValidationError';
import css from './FieldToggle.module.css';

const FieldToggleComponent = props => {
  const {
    id,
    label,
    checked,
    onChange,
    disabled,
    isPrimary,
    labelClassName,
    rootClassName,
    className,
    input,
    meta,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const handleOnChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  const handleKeyPress = e => {
    if (e.keycode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  };

  const toggleClassnames = classNames(css.toggle, {
    [css.primary]: isPrimary,
    [css.secondary]: !isPrimary,
  });

  const labelClasses = labelClassName || css.label;

  const toggleProps = {
    className: toggleClassnames,
    id,
    ...input,
    onChange: handleOnChange,
    ...rest,
  };

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      {!!label && <label className={labelClasses}>{label}</label>}
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        tabIndex={disabled ? -1 : 1}
        onKeyDown={handleKeyPress}
        {...toggleProps}
      />
      <label htmlFor={id} className={css.toggleLabel} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldToggleComponent.defaultProps = {
  label: null,
  checked: false,
  disabled: false,
  isPrimary: false,
  onChange: () => {},
};

FieldToggleComponent.propTypes = {
  id: string.isRequired,
  label: string,
  checked: bool,
  onChange: func,
  disabled: bool,
  isPrimary: bool,
  labelClassName: string,
  rootClassName: string,
  className: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldToggle = props => {
  return <Field component={FieldToggleComponent} {...props} />;
};

export default FieldToggle;
