import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import { IconArrowHead, InlineTextButton, NamedLink } from '..';

import css from './AboutNavBar.module.css';

const AboutNavBar = props => {
  const {
    className,
    rootClassName,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  
  return (
    <div className={classes}>
      <LinkButton
        href="/about-us/the-movement/"
        id={css['section-movement']}
        text="The Movement"
      />
      <LinkButton
        href="/about-us/our-commitment/"
        id={css['section-commitment']}
        text="Our Commitment"
      />
      <LinkButton
        href="/about-us/village-council/"
        id={css['section-villagecouncil']}
        text="Village Council"
      />
      <LinkButton
        href="/about-us/our-story/"
        id={css['section-ourstory']}
        text="Our Story"
      />
    </div>
  );
};

function LinkButton({ href, id, text }) {
  return (
    <a
      href={href}
      id={id}
      className={css.staticLink}
    >
      {text}
    </a>
  );
}

AboutNavBar.defaultProps = {
  rootClassName: null,
  className: null,
};

AboutNavBar.propTypes = {
  rootClassName: string,
  className: string,
};

export default AboutNavBar;
