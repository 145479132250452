import React, { useRef } from 'react';
import { string, func, object, bool, shape } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldUploadFile.module.css';

const FieldUploadFile = props => {
  const { className, onFileUploadHandler, inputRef, ...rest } = props;
  const containerRef = useRef();

  const dragOverHandler = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    containerRef.current.style.borderColor = '#bba280';
  };

  const dragLeaveHandler = event => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    containerRef.current.style.borderColor = '#e6e6e6';
  };

  const dropHandler = (event) => {
    event.preventDefault();
    containerRef.current.style.borderColor = '#e6e6e6';
    if (event.dataTransfer.items) {
      const files = [...event.dataTransfer.items].map(item => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          return file;
        }
      });
      onFileUploadHandler(files);
      event.target.value = null;
    } else {
      onFileUploadHandler([...event.dataTransfer.files])
    }
  }

  const classes = classNames(css.wrapper, className);

  return (
    <Field {...rest}>
      {fieldprops => {
        const { accept, input, label, placeholder, disabled: fieldDisabled, multiple = false } = fieldprops;
        const { name } = input;
        const onChange = e => {
          const { files } = e.target;
          onFileUploadHandler([...files]);
          e.target.value = null;
        };
        const inputProps = {
          ref: inputRef,
          accept,
          id: name,
          name,
          onChange,
          type: 'file',
          multiple,
        };
        return (
          <div
            ref={containerRef}
            className={classes}
            onClick={() => {
              inputRef.current.click();
            }}
            onDragOver={dragOverHandler}
            onDragLeave={dragLeaveHandler}
            onDrop={dropHandler}
          >
            {fieldDisabled ? null : <input {...inputProps} className={css.input} />}
            <label
              onClick={event => {
                event.preventDefault();
              }}
              htmlFor={name}
              className={css.label}
            >
              {label}
            </label>
            {placeholder && <p className={css.placeholder}>{placeholder}</p>}
          </div>
        );
      }}
    </Field>
  );
};

FieldUploadFile.defaultProps = {
  className: null,
  inputRef: null,
  accept: undefined,
  label: null,
  placeholder: null,
  disabled: false,
  multiple: true,
  input: { onChange: () => {} },
};

FieldUploadFile.propTypes = {
  className: string,
  onFileUploadHandler: func.isRequired,
  inputRef: object,
  accept: string,
  label: string,
  placeholder: string,
  disabled: bool,
  multiple: bool,
  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
};

export default FieldUploadFile;
