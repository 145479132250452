import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import toArray from 'lodash/toArray';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual';
import { Field } from 'react-final-form';
import { statesUSLower48 } from '../../config/configStates';
import { getLabelByKey } from '../../util/data';
import {
  ValidationError,
  IconArrowHead,
  Chip,
  OutsideClickHandler,
} from '../../components';

import css from './FieldTagSelect.module.css';

const getSearchResult = (optionsList, searchValue, selectedOptions) => {
  const result = {
    firstPriority: [],
    secondPriority: [],
    lastPriority: [],
  };

  if (!searchValue) {
    return optionsList.filter(option => !find(selectedOptions, option.key));
  }

  const parsedSearchValue = searchValue?.toLowerCase();
  for (const option of optionsList) {
    if (
      selectedOptions.find(selectedOption => selectedOption.key === option.key)
    ) {
      continue;
    }

    const parsedOption = option.label.toLowerCase();
    const searchResultIndex = parsedOption.search(parsedSearchValue);

    if (searchResultIndex < 0) {
      continue;
    }

    // Case 1: result found at the first characters of first word
    if (searchResultIndex === 0) {
      result.firstPriority.push(option);
    }
    // Case 2: result found at the first characters of a word and after a spacing
    else if (
      searchResultIndex > 0 &&
      parsedOption[searchResultIndex - 1] === ' '
    ) {
      result.secondPriority.push(option);
    }
    // Case 3: result is in between the first and last letters of a word
    else {
      result.lastPriority.push(option);
    }
  }
  return flatten(toArray(result));
};

const FieldTagSelectComponent = props => {
  const {
    rootClassName,
    className,
    labelClassName,
    customErrorText,
    id,
    label,
    allOptionLabel,
    selectOptionLabel,
    searchPlaceholder,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    placeholder,
    selectMenuClassName,
    selectOptions,
    setAvailableStates,
    ...rest
  } = props;
  
  const [isOpenSelectMenu, setIsOpenSelectMenu] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(input.value || []);
  const [searchValue, setSearchValue] = useState('');
  const [availableOptions, setAvailableOptions] = useState(
    getSearchResult(selectOptions, searchValue, selectedOptions)
  );
  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  useEffect(() => {
    setSelectedOptions(input.value || []);
  }, [input.value])

  const { valid, invalid, touched, error } = meta;
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };

  const classes = classNames(rootClassName || css.root, className);
  const selectClasses = classNames({
    [css.selectBoxSuccess]: valid,
    [css.selectBoxError]: hasError,
  });
  const selectBoxClasses = classNames(selectClasses, css.selectBox);

  const handleRemoveOption = o => e => {
    if (find(selectedOptions, o)) {
      setIsOpenSelectMenu(false); // Close the select menu to force re-render
      const newAvailableSelectOptions = orderBy([...selectOptions, o], 'label');
      setAvailableStates(newAvailableSelectOptions);
      const filteredArray = selectedOptions.filter(item => item.key !== o.key);
      setSelectedOptions(filteredArray);
      handleChangeInput(!isEmpty(filteredArray) ? filteredArray : null)();
      setAvailableOptions(
        getSearchResult(newAvailableSelectOptions, searchValue, filteredArray)
      );
    }
  };

  const handleSelectOption = o => e => {
    if (!find(selectedOptions, o.key)) {
      const newAvailableSelectOptions = selectOptions.filter(
        item => item.key !== o.key
      );
      setAvailableStates(newAvailableSelectOptions);
      const newSelectedOptions = [...selectedOptions, o];
      setSelectedOptions(newSelectedOptions);
      setAvailableOptions(
        getSearchResult(
          newAvailableSelectOptions,
          searchValue,
          newSelectedOptions
        )
      );
      handleChangeInput(newSelectedOptions)();
    }
  };

  const exactSameComparator = (a, b) => a.key === b.key;

  const handleSelect48LowerStates = () => {
    const newSelectedOptions = [...statesUSLower48];
    const newAvailableSelectOptions = differenceWith(
      selectOptions, newSelectedOptions, exactSameComparator
    );
    setAvailableStates(newAvailableSelectOptions);
    setSelectedOptions(newSelectedOptions);
    setAvailableOptions(
      getSearchResult(newAvailableSelectOptions, searchValue, newSelectedOptions)
    );
    handleChangeInput(newSelectedOptions)();
  }

  const handleChangeInput = value => e => {
    input.onChange(value);
  };

  const inputProps = {
    id,
    className: css.input,
    defaultValue: selectedOptions?.key,
    onChange: e => handleChangeInput(e),
    ...input,
    ...rest,
  };

  const valueToDisplay = !isEmpty(selectedOptions)
    ? selectedOptions.map(item => {
        return (
          <Chip
            key={item.key}
            className={css.chip}
            content={item.label}
            onRemove={handleRemoveOption(item)}
          />
        );
      })
    : null;

  const search = debounce(searchValue => {
    setAvailableOptions(
      getSearchResult(selectOptions, searchValue, selectedOptions)
    );
  }, 700);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
    setIsOpenSelectMenu(true);
    search(value);
  };

  const show48LowerStatesOption = (differenceWith(statesUSLower48, availableOptions, isEqual).length === 0);

  return (
    <OutsideClickHandler
      className={classes}
      onOutsideClick={() => {
        if (isOpenSelectMenu) {
          setIsOpenSelectMenu(false);
          input.onBlur();
        }
      }}
    >
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={css.displayValueArea}>{valueToDisplay} </div>
      <button
        type="button"
        className={selectBoxClasses}
        onClick={() => {
          setAvailableOptions( getSearchResult(selectOptions, searchValue, selectedOptions))
          setIsOpenSelectMenu(!isOpenSelectMenu);
        }}
      >
        <input
          className={css.search}
          type="text"
          value={searchValue}
          placeholder={
            isEmpty(selectedOptions) ? placeholder : searchPlaceholder
          }
          onChange={e => handleSearch(e)}
        />

        <IconArrowHead direction="down" rootClassName={css.arrowHead} />
      </button>

      {isOpenSelectMenu && (
        <div className={selectMenuClassName || css.selectMenu}>
          {show48LowerStatesOption && allOptionLabel && !isEmpty(availableOptions) && (
            <div
              className={css.option}
              onClick={handleSelect48LowerStates}
            >
              {allOptionLabel}
            </div>
          )}
          {selectOptionLabel && show48LowerStatesOption && (
            <div className={css.unSelectOptionLabel}>{selectOptionLabel}</div>
          )}
          {!isEmpty(availableOptions) ? (
            availableOptions.map(o => (
              <div
                className={css.option}
                key={o.key}
                onClick={handleSelectOption(o)}
              >
                {o.label}
              </div>
            ))
          ) : (
            <div className={classNames(css.unSelectOptionLabel, css.noOptions)}>
              <FormattedMessage id="FieldTagSelect.noOptions" />
            </div>
          )}
        </div>
      )}
      <input {...inputProps} />
      <ValidationError fieldMeta={fieldMeta} />
    </OutsideClickHandler>
  );
};

FieldTagSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  labelClassName: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  allOptionLabel: null,
  selectOptionLabel: null,
  isUncontrolled: false,
  placeholder: null,
  searchPlaceholder: null,
  selectOptions: [],
  selectMenuClassName: null,
};

const { string, object, array, func, shape, bool } = PropTypes;

FieldTagSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  labelClassName: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  allOptionLabel: string,
  selectOptionLabel: string,
  searchPlaceholder: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  placeholder: string,
  selectOptions: array,
  selectMenuClassName: string,
};

const FieldTagSelect = props => {
  return <Field component={FieldTagSelectComponent} {...props} />;
};

export default FieldTagSelect;
