import isEmpty from 'lodash/isEmpty';

import { storableError } from '../../util/errors';
import {
  convertUnitToSubUnit,
  unitDivisor,
  storeCurrencyRateData,
  getStoredCurrencyRate,
  exchangeCurrencies,
  getStoredUserSelectedCurrency,
} from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import {
  createImageVariantConfig,
  types as sdkTypes,
} from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import {
  fetchCurrencyRates,
  getProductFilters,
  getProductCategories,
  getSectionData,
} from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { END_OF_PRODUCT_TYPE_SEARCH, JH_LISTING_TYPES } from '../../util/types';
import config from '../../config/configDefault';
import { getCategoryKeyFromURLParams } from './SearchPage.shared';

const { Money } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 96;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST =
  'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS =
  'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR =
  'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING =
  'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCHING_CURRENCY_RATE_REQUEST =
  'app/SearchPage/FETCHING_CURRENCY_RATE_REQUEST';
export const FETCHING_CURRENCY_RATE_SUCCESS =
  'app/SearchPage/FETCHING_CURRENCY_RATE_SUCCESS';
export const FETCHING_CURRENCY_RATE_ERROR =
  'app/SearchPage/FETCHING_CURRENCY_RATE_ERROR';

export const FETCHING_PRODUCT_FILTERS_REQUEST =
  'app/SearchPage/FETCHING_PRODUCT_FILTERS_REQUEST';
export const FETCHING_PRODUCT_FILTERS_SUCCESS =
  'app/SearchPage/FETCHING_PRODUCT_FILTERS_SUCCESS';
export const FETCHING_PRODUCT_FILTERS_ERROR =
  'app/SearchPage/FETCHING_PRODUCT_FILTERS_ERROR';

export const FETCHING_PRODUCT_CATEGORIES_REQUEST =
  'app/SearchPage/FETCHING_PRODUCT_CATEGORIES_REQUEST';
export const FETCHING_PRODUCT_CATEGORIES_SUCCESS =
  'app/SearchPage/FETCHING_PRODUCT_CATEGORIES_SUCCESS';
export const FETCHING_PRODUCT_CATEGORIES_ERROR =
  'app/SearchPage/FETCHING_PRODUCT_CATEGORIES_ERROR';

export const FETCHING_SECTION_DATA_REQUEST =
  'app/SearchPage/FETCHING_SECTION_DATA_REQUEST';
export const FETCHING_SECTION_DATA_SUCCESS =
  'app/SearchPage/FETCHING_SECTION_DATA_SUCCESS';
export const FETCHING_SECTION_DATA_ERROR =
  'app/SearchPage/FETCHING_SECTION_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  productFiltersData: [],
  fetchingProductFiltersError: null,
  fetchingProductFilters: false,
  productCategoriesData: [],
  fetchingProductCategoriesError: null,
  fetchingProductCategories: false,
  fetchingSectionData: false,
  fetchingSectionDataError: null,
  sectionData: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
      };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    case FETCHING_CURRENCY_RATE_REQUEST:
      return {
        ...state,
        fetchingCurrencyRateInProgress: true,
        fetchingCurrencyRateError: null,
      };
    case FETCHING_CURRENCY_RATE_SUCCESS:
      return {
        ...state,
        fetchingCurrencyRateInProgress: false,
        currencyRatesData: payload,
      };
    case FETCHING_CURRENCY_RATE_ERROR:
      return {
        ...state,
        fetchingCurrencyRateInProgress: false,
        fetchingCurrencyRateError: payload,
      };

    case FETCHING_PRODUCT_FILTERS_REQUEST:
      return {
        ...state,
        fetchingProductFilters: true,
        fetchingProductFiltersError: null,
      };
    case FETCHING_PRODUCT_FILTERS_SUCCESS:
      return {
        ...state,
        fetchingProductFilters: false,
        productFiltersData: payload,
      };
    case FETCHING_PRODUCT_FILTERS_ERROR:
      return {
        ...state,
        fetchingProductFilters: false,
        fetchingProductFiltersError: payload,
      };

    case FETCHING_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        fetchingProductCategories: true,
        fetchingProductCategoriesError: null,
      };
    case FETCHING_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        fetchingProductCategories: false,
        productCategoriesData: payload,
      };
    case FETCHING_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        fetchingProductCategories: false,
        fetchingProductCategoriesError: payload,
      };

    case FETCHING_SECTION_DATA_REQUEST:
      return {
        ...state,
        fetchingSectionData: true,
        fetchingSectionDataError: null,
      };
    case FETCHING_SECTION_DATA_SUCCESS:
      return {
        ...state,
        fetchingSectionData: false,
        sectionData: payload,
      };
    case FETCHING_SECTION_DATA_ERROR:
      return {
        ...state,
        fetchingSectionData: false,
        fetchingSectionDataError: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

export const fetchingCurrencyRateDataInProgress = state => {
  return state.user.fetchingCurrencyRateDataInProgress;
};

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrencyRateRequest = () => ({
  type: FETCHING_CURRENCY_RATE_REQUEST,
});

export const fetchCurrencyRateSuccess = payload => ({
  type: FETCHING_CURRENCY_RATE_SUCCESS,
  payload,
});

export const fetchCurrencyRateError = e => ({
  type: FETCHING_CURRENCY_RATE_ERROR,
  error: true,
  payload: e,
});

export const fetchProductFiltersRequest = () => ({
  type: FETCHING_PRODUCT_FILTERS_REQUEST,
});

export const fetchProductFiltersSuccess = payload => ({
  type: FETCHING_PRODUCT_FILTERS_SUCCESS,
  payload,
});

export const fetchProductFiltersError = e => ({
  type: FETCHING_PRODUCT_FILTERS_ERROR,
  error: true,
  payload: e,
});

export const fetchProductCategoriesRequest = () => ({
  type: FETCHING_PRODUCT_CATEGORIES_REQUEST,
});

export const fetchProductCategoriesSuccess = payload => ({
  type: FETCHING_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

export const fetchProductCategoriesError = e => ({
  type: FETCHING_PRODUCT_CATEGORIES_ERROR,
  error: true,
  payload: e,
});

export const fetchSectionDataRequest = () => ({
  type: FETCHING_SECTION_DATA_REQUEST,
});

export const fetchSectionDataSuccess = payload => ({
  type: FETCHING_SECTION_DATA_SUCCESS,
  payload,
});

export const fetchSectionDataError = e => ({
  type: FETCHING_SECTION_DATA_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const searchListings = (searchParams, config) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(searchListingsRequest(searchParams));
  dispatch(handleFetchingCurrencyRates());
  dispatch(fetchAttributesSearchConfig(searchParams));

  // dispatch(fetchSectionData());

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
          // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
          // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currency));
    const currentSelectedCurrency = getStoredUserSelectedCurrency();
    const values = priceParam ? priceParam.split(',') : [];
    const { currencyRatesData } = getState().SearchPage;
    const { currency } = config;
    const convertedMoneyValues =
      currentSelectedCurrency && currencyRatesData
        ? [
            exchangeCurrencies({
              from: currentSelectedCurrency,
              to: currency,
              price: new Money(values[0], currency),
              currencyRatesData,
            }),
            exchangeCurrencies({
              from: currentSelectedCurrency,
              to: currency,
              price: new Money(values[1], currency),
              currencyRatesData,
            }),
          ]
        : [];

    return priceParam && values.length === 2
      ? !currentSelectedCurrency
        ? {
            price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
          }
        : {
            price: [
              inSubunits(convertedMoneyValues[0].amount),
              inSubunits(convertedMoneyValues[1].amount) + 1,
            ].join(','),
          }
      : {};
  };
  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(
      f => f.key === 'dates'
    );
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues
      ? parseDateFromISO8601(values[0], searchTZ)
      : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable
      ? daysBetween(startDate, endDate)
      : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          // Availability can be time-full or time-partial.
          // However, due to prolonged time window, we need to use time-partial.
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };
  const {
    perPage,
    price,
    dates,
    sort,
    ...rest
  } = searchParams;

  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe =
    sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

const normalizeTag = tag => {
  return tag.split('-').join(' ');
};

export const handleFetchingCurrencyRates = (baseCurrency = config.currency) => (
  dispatch,
  getState,
  sdk
) => {
  if (fetchingCurrencyRateDataInProgress(getState())) {
    return Promise.reject(new Error('Fetching currency already in progress'));
  }
  dispatch(fetchCurrencyRateRequest());

  const currencyCacheKey = `${
    config.currencyConfig.cachePrefix
  }_${baseCurrency.toUpperCase()}`;

  const storedCurrencyRateData = getStoredCurrencyRate(currencyCacheKey);

  if (storedCurrencyRateData) {
    return dispatch(fetchCurrencyRateSuccess(storedCurrencyRateData));
  }

  return fetchCurrencyRates(baseCurrency)
    .then(res => {
      storeCurrencyRateData(res.data, currencyCacheKey);
      dispatch(fetchCurrencyRateSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchCurrencyRateError(storableError(e)));
    });
};

const fetchAttributesSearchConfig = searchParams => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchProductFiltersRequest());

  return getProductFilters()
    .then(res => {
      const searchKeys = Object.keys(searchParams);
      const productFilters = res.data.filter(currentFilter => {
        const { dependOn } = currentFilter;

        if (!dependOn) {
          return true;
        }

        if (!searchKeys.includes(`pub_${dependOn.field}`)) {
          return false;
        }

        if (searchParams[`pub_${dependOn.field}`] === dependOn.value) {
          return true;
        }

        return false;
      });
      dispatch(fetchProductFiltersSuccess(productFilters));
    })
    .catch(e => {
      dispatch(fetchProductFiltersError(storableError(e)));
    });
};

const findExistData = (data, item) => {
  return data?.find(i => i.key === item);
};

export const fetchProductCategories = searchParams => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchProductCategoriesRequest());

  return getProductCategories()
    .then(response => {
      const productCategories = response.data.reduce(
        (res, record) => {
          const {
            top_level_category,
            top_level_category_label,
            parent_category,
            parent_category_label,
            parent_category_img,
            sub_category,
            sub_category_label,
            sub_category_img,
            page_title = sub_category_label ||
              parent_category_label ||
              top_level_category_label,
            page_description = sub_category_label ||
              parent_category_label ||
              top_level_category_label,
            page_h1_title = sub_category_label ||
              parent_category_label ||
              top_level_category_label,
            url_key,
          } = record.fields;

          if (!res.childrenOfTopLevelCategory[top_level_category]) {
            if (!!parent_category && !!parent_category_label) {
              res.childrenOfTopLevelCategory[top_level_category] = [
                {
                  key: parent_category,
                  label: parent_category_label,
                  url_key,
                },
              ];
            } else {
              res.childrenOfTopLevelCategory[top_level_category] = [];
            }
          } else if (
            !findExistData(
              res.childrenOfTopLevelCategory[top_level_category],
              parent_category
            )
          ) {
            res.childrenOfTopLevelCategory[top_level_category].push({
              key: parent_category,
              label: parent_category_label,
              url_key,
            });
          }

          const subCategoryItem = !!sub_category
            ? {
                key: sub_category,
                label: sub_category_label,
                url_key,
              }
            : {
                key: END_OF_PRODUCT_TYPE_SEARCH,
              };

          if (res.childrenOfParentCategory[parent_category]) {
            res.childrenOfParentCategory[parent_category].push(subCategoryItem);
          } else {
            res.childrenOfParentCategory[parent_category] = [subCategoryItem];
          }

          if (
            top_level_category &&
            !findExistData(res.topLevelCategory, top_level_category)
          ) {
            res.topLevelCategory.push({
              key: top_level_category,
              label: top_level_category_label,
              page_title,
              page_description,
              page_h1_title,
              url_key,
            });
          }

          if (
            parent_category &&
            !findExistData(res.parentCategory, parent_category)
          ) {
            res.parentCategory.push({
              key: parent_category,
              label: parent_category_label,
              img: parent_category_img,
              parent: top_level_category,
              page_title,
              page_description,
              page_h1_title,
              url_key,
            });
          }

          if (sub_category && !findExistData(res.productType, sub_category)) {
            res.productType.push({
              key: sub_category,
              label: sub_category_label,
              parent: parent_category,
              img: sub_category_img,
              page_title,
              page_description,
              page_h1_title,
              url_key,
            });
          }

          return res;
        },
        {
          topLevelCategory: [],
          parentCategory: [],
          productType: [],
          childrenOfTopLevelCategory: {},
          childrenOfParentCategory: {},
        }
      );
      dispatch(fetchProductCategoriesSuccess(productCategories));
      return productCategories;
    })
    .catch(e => {
      dispatch(fetchProductCategoriesError(storableError(e)));
    });
};

export const fetchSectionData = () => (dispatch, getState, sdk) => {
  dispatch(fetchSectionDataRequest());

  return getSectionData()
    .then(response => {
      // Get the fields attributes from each index in the response data
      const sectionData = response.data.map(item => {
        const { fields } = item;
        return fields;
      });
      dispatch(fetchSectionDataSuccess(sectionData));
    })
    .catch(e => {
      dispatch(fetchSectionDataError(storableError(e)));
    });
};

export const loadData = (params, search, config) => async (
  dispatch,
  getState
) => {
  // if (isEmpty(getState().SearchPage.productCategoriesData)) {
  //   await dispatch(fetchProductCategories());
  // }

  const { productCategoriesData } = getState().SearchPage;

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const {
    cloudTag,
    topLevelCategory: topLevelCategoryURLKey,
    parentCategory: parentCategoryURLKey,
    productType: productTypeURLKey,
  } = params;

  const categorySearchParams = getCategoryKeyFromURLParams({
    params: {
      topLevelCategory: topLevelCategoryURLKey,
      parentCategory: parentCategoryURLKey,
      productType: productTypeURLKey,
    },
    productCategoriesData,
  });

  const {
    topLevelCategory,
    parentCategory,
    productType,
  } = categorySearchParams;

  const searchParams = {
    ...rest,
    ...originMaybe,
    page,
    pub_stock: "1,",
    pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'currentStock'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'publicData.listingType',
      'publicData.transactionProcessAlias',
      'publicData.unitType',
      // These help rendering of 'purchase' listings,
      // when transitioning from search page to listing page
      'publicData.productName',
      'publicData.makerName',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData.storeName',
      'profile.publicData.storeNameLabel',
    ],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    // 'limit.images': 1,
  };

  if (cloudTag && typeof cloudTag === 'string') {
    searchParams.pub_tagsToSearch = cloudTag;
  }

  // Prepare params for search with category
  if (!!topLevelCategory) {
    searchParams.pub_top_level_category = topLevelCategory;
  }

  if (!!parentCategory) {
    if (
      productCategoriesData.childrenOfParentCategory[parentCategory].length <=
      1
    ) {
      searchParams.pub_product_type = parentCategory;
    } else {
      searchParams.pub_parent_category = parentCategory;
    }
  }

  if (!!productType) {
    searchParams.pub_product_type = productType;
  }

  return dispatch(searchListings(searchParams, config));
};
