export const getIsOutsideRangeFn = ({
  defaultIsOutsideRange,
  isOutsideRangeFromProps,
}) => {
  let isOutsideRangeFn;

  if (defaultIsOutsideRange) {
    isOutsideRangeFn = defaultIsOutsideRange;
  }
  if (isOutsideRangeFromProps) {
    isOutsideRangeFn = isOutsideRangeFromProps;
  }

  return isOutsideRangeFn;
};
