import React, { useEffect } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductCategories } from '../SearchPage/SearchPage.duck';

import CategoryBar from '../../components/CategoryBar/CategoryBar';
import { IconSpinner } from '../../components';

export const CategoryBarContainerComponent = props => {
  const { productCategoriesData } = props;
  const dispatch = useDispatch();
  const {
    productCategoriesData: productCategoriesDataFromDuck,
    fetchingProductCategories,
  } = useSelector(state => state.SearchPage);

  useEffect(() => {
    if (productCategoriesDataFromDuck.length === 0) {
      dispatch(fetchProductCategories());
    }
  }, [productCategoriesDataFromDuck.length]);

  if (productCategoriesData.length === 0 && fetchingProductCategories)
    return <IconSpinner />;

  return <CategoryBar categoriesList={productCategoriesData} />;
};

const mapStateToProps = state => {
  const { productCategoriesData } = state.SearchPage;
  return {
    productCategoriesData,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CategoryBarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  )
)(CategoryBarContainerComponent);

export default CategoryBarContainer;
