/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React from 'react';
import { node, string, object, func, bool, array } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { FieldCartItemGroup, ValidationError } from '..';
import isEmpty from 'lodash/isEmpty';

import css from './FieldStoreItemGroup.module.css';

const FieldStoreItemGroupComponent = props => {
  const {
    className,
    rootClassName,
    label,
    subLabel,
    id,
    fieldName,
    options,
    meta,
    onRemoveItem,
    removeItemInProgress,
    onUpdateItemQuantity,
    prepareItemBeforeCheckoutInProgress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return !isEmpty(options) ? (
    <fieldset className={classes}>
      {label ? <legend className={css.label}>{label}</legend> : null}
      {subLabel ? <p className={css.subLabel}>{subLabel}</p> : null}
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.id.uuid}.${index}`;
          return (
            !!option && (
              <li key={fieldId} className={css.item}>
                <FieldCartItemGroup
                  id={fieldId}
                  name={fieldName}
                  listingData={option}
                  value={`${option.id.uuid}${index}`}
                  onRemoveItem={onRemoveItem}
                  removeItemInProgress={removeItemInProgress}
                  onUpdateItemQuantity={onUpdateItemQuantity}
                  prepareItemBeforeCheckoutInProgress={prepareItemBeforeCheckoutInProgress}
                />
              </li>
            )
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset>
  ) : null;
};

FieldStoreItemGroupComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  onRemoveItem: () => null,
  removeItemInProgress: false,
  onUpdateItemQuantity: () => null,
  prepareItemBeforeCheckoutInProgress: false,
};

FieldStoreItemGroupComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  subLabel: node,
  options: array.isRequired,
  fieldName: string.isRequired,
  meta: object,

  onRemoveItem: func,
  removeItemInProgress: bool,
  onUpdateItemQuantity: func,
  prepareItemBeforeCheckoutInProgress: bool,
};

const FieldStoreItemGroup = props => {
  return <Field fieldName={props.name} component={FieldStoreItemGroupComponent} {...props} />;
};

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldStoreItemGroup.propTypes = {
  name: string.isRequired,
};

export default FieldStoreItemGroup;
