import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCurrencyBasedOnLocation,
  selectCurrency,
} from '../ducks/currency.duck';
import { getStoredUserSelectedCurrency } from './currency';
import config from '../config/configDefault';

export const GetCurrencyBasedOnLocation = () => {
  const dispatch = useDispatch();
  const updateCurrency = currency => dispatch(selectCurrency(currency));
  const getUserCurrency = ({ latitude, longitude }) =>
    dispatch(getCurrencyBasedOnLocation({ latitude, longitude }));

  useEffect(() => {
    const currencyUserSelected = getStoredUserSelectedCurrency();
    if (!currencyUserSelected) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          getCountryCode,
          handleLocationError
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
        updateCurrency(config.currency);
      }
    }
  }, []);

  const getCountryCode = position => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    getUserCurrency({ latitude, longitude });
  };

  const handleLocationError = error => {
    updateCurrency(config.currency);
  };
  return null;
};
