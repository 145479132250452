import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconShoppingBag.module.css';

const IconShoppingBag = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7229 7.18864C16.7229 4.89449 14.8632 3.03471 12.569 3.03471C11.4643 3.03002 10.4032 3.4656 9.62034 4.24512C8.83751 5.02465 8.39745 6.08389 8.39746 7.18864"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M15.42 11.5182H15.376"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M9.81262 11.5182H9.76862"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1219 7.39966L21.0105 22.0349L3.49023 22.0349L4.37881 7.39966L20.1219 7.39966Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

IconShoppingBag.defaultProps = {
  rootClassName: null,
  className: null,
};

IconShoppingBag.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconShoppingBag;
