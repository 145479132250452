// List of states for the US
export const statesUS = [
  { key: 'AL', label: 'Alabama' },
  { key: 'AK', label: 'Alaska' },
  { key: 'AZ', label: 'Arizona' },
  { key: 'AR', label: 'Arkansas' },
  { key: 'CA', label: 'California' },
  { key: 'CO', label: 'Colorado' },
  { key: 'CT', label: 'Connecticut' },
  { key: 'DE', label: 'Delaware' },
  { key: 'FL', label: 'Florida' },
  { key: 'GA', label: 'Georgia' },
  { key: 'HI', label: 'Hawaii' },
  { key: 'ID', label: 'Idaho' },
  { key: 'IL', label: 'Illinois' },
  { key: 'IN', label: 'Indiana' },
  { key: 'IA', label: 'Iowa' },
  { key: 'KS', label: 'Kansas' },
  { key: 'KY', label: 'Kentucky' },
  { key: 'LA', label: 'Louisiana' },
  { key: 'ME', label: 'Maine' },
  { key: 'MD', label: 'Maryland' },
  { key: 'MA', label: 'Massachusetts' },
  { key: 'MI', label: 'Michigan' },
  { key: 'MN', label: 'Minnesota' },
  { key: 'MS', label: 'Mississippi' },
  { key: 'MO', label: 'Missouri' },
  { key: 'MT', label: 'Montana' },
  { key: 'NE', label: 'Nebraska' },
  { key: 'NV', label: 'Nevada' },
  { key: 'NH', label: 'New Hampshire' },
  { key: 'NJ', label: 'New Jersey' },
  { key: 'NM', label: 'New Mexico' },
  { key: 'NY', label: 'New York' },
  { key: 'NC', label: 'North Carolina' },
  { key: 'ND', label: 'North Dakota' },
  { key: 'OH', label: 'Ohio' },
  { key: 'OK', label: 'Oklahoma' },
  { key: 'OR', label: 'Oregon' },
  { key: 'PA', label: 'Pennsylvania' },
  { key: 'PR', label: 'Puerto Rico' },
  { key: 'RI', label: 'Rhode Island' },
  { key: 'SC', label: 'South Carolina' },
  { key: 'SD', label: 'South Dakota' },
  { key: 'TN', label: 'Tennessee' },
  { key: 'TX', label: 'Texas' },
  { key: 'UT', label: 'Utah' },
  { key: 'VT', label: 'Vermont' },
  { key: 'VA', label: 'Virginia' },
  { key: 'WA', label: 'Washington' },
  { key: 'DC', label: 'Washington D.C.' },
  { key: 'WV', label: 'West Virginia' },
  { key: 'WI', label: 'Wisconsin' },
  { key: 'WY', label: 'Wyoming' },
];

// List of 48 lower states in the US, excluding Hawaii and Alaska
export const statesUSLower48 = [
  { key: "AL", label: "Alabama" },
  { key: "AZ", label: "Arizona" },
  { key: "AR", label: "Arkansas" },
  { key: "CA", label: "California" },
  { key: "CO", label: "Colorado" },
  { key: "CT", label: "Connecticut" },
  { key: "DE", label: "Delaware" },
  { key: "FL", label: "Florida" },
  { key: "GA", label: "Georgia" },
  { key: "ID", label: "Idaho" },
  { key: "IL", label: "Illinois" },
  { key: "IN", label: "Indiana" },
  { key: "IA", label: "Iowa" },
  { key: "KS", label: "Kansas" },
  { key: "KY", label: "Kentucky" },
  { key: "LA", label: "Louisiana" },
  { key: "ME", label: "Maine" },
  { key: "MD", label: "Maryland" },
  { key: "MA", label: "Massachusetts" },
  { key: "MI", label: "Michigan" },
  { key: "MN", label: "Minnesota" },
  { key: "MS", label: "Mississippi" },
  { key: "MO", label: "Missouri" },
  { key: "MT", label: "Montana" },
  { key: "NE", label: "Nebraska" },
  { key: "NV", label: "Nevada" },
  { key: "NH", label: "New Hampshire" },
  { key: "NJ", label: "New Jersey" },
  { key: "NM", label: "New Mexico" },
  { key: "NY", label: "New York" },
  { key: "NC", label: "North Carolina" },
  { key: "ND", label: "North Dakota" },
  { key: "OH", label: "Ohio" },
  { key: "OK", label: "Oklahoma" },
  { key: "OR", label: "Oregon" },
  { key: "PA", label: "Pennsylvania" },
  { key: "RI", label: "Rhode Island" },
  { key: "SC", label: "South Carolina" },
  { key: "SD", label: "South Dakota" },
  { key: "TN", label: "Tennessee" },
  { key: "TX", label: "Texas" },
  { key: "UT", label: "Utah" },
  { key: "VT", label: "Vermont" },
  { key: "VA", label: "Virginia" },
  { key: "WA", label: "Washington" },
  { key: 'DC', label: 'Washington D.C.' },
  { key: "WV", label: "West Virginia" },
  { key: "WI", label: "Wisconsin" },
  { key: "WY", label: "Wyoming" }
];
