import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import css from './GenericError.module.css';

const TIME_TO_SHOW_ERROR = 2000;

const GenericError = props => {
  const { show, messageId } = props;
  const [shouldShowError, setShouldShowError] = useState(show);

  useEffect(() => {
    setShouldShowError(show);
  }, [show]);

  useEffect(() => {
    if (!shouldShowError) return;
    const timer = setTimeout(() => {
      setShouldShowError(!shouldShowError);
    }, TIME_TO_SHOW_ERROR);

    return () => clearTimeout(timer);
  }, [shouldShowError]);

  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: shouldShowError,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id={messageId} />
        </p>
      </div>
    </div>
  );
};

GenericError.defaultProps = {
  show: false,
};

GenericError.propTypes = {
  show: bool,
  messageId: string.isRequired,
};

export default GenericError;
