import React, { useState } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import _ from 'lodash';
import { END_OF_PRODUCT_TYPE_SEARCH } from '../../util/types';
import { IconArrowHead, InlineTextButton, NamedLink } from '..';

import css from './CategoryBar.module.css';

const CategoryBar = props => {
  const {
    className,
    rootClassName,
    categoriesList,
    fetchingProductCategories,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const [categorySelectedIndex, setCategorySelectedIndex] = useState();
  const [subCategorySelectedIndex, setSubCategorySelectedIndex] = useState();

  return (
    !fetchingProductCategories && (
      <nav className={classes}>
        {categoriesList.topLevelCategory?.map(topCategory => {
          return (
            <div
              key={topCategory.key}
              className={classNames(
                css.categoryContainer,
                css.containerOnMobile,
                categorySelectedIndex === topCategory.key ? css.open : null
              )}
            >
              <NamedLink
                key={topCategory.key}
                name="CategoryPage"
                className={css.categoryLink}
                params={{ topLevelCategory: topCategory.url_key }}
              >
                <p className={css.link}>{topCategory.label}</p>
              </NamedLink>
              {categoriesList.childrenOfTopLevelCategory[topCategory.key]
                .length ? (
                <InlineTextButton
                  className={css.arrowButton}
                  onClick={() => setCategorySelectedIndex(topCategory.key)}
                >
                  <IconArrowHead
                    direction="down"
                    size="small"
                    className={css.icon}
                  />
                </InlineTextButton>
              ) : null}
              {categoriesList.childrenOfTopLevelCategory[topCategory.key]
                .length ? (
                <div
                  className={classNames(css.subCategoryContainer, {
                    [css.displayItem]:
                      categorySelectedIndex === topCategory.key,
                  })}
                >
                  {categoriesList.childrenOfTopLevelCategory[
                    topCategory.key
                  ]?.map(parentCategory => {
                    if (!_.isString(parentCategory.key)) {
                      return null;
                    }
                    const hasNestedCategoriesList =
                      categoriesList.childrenOfParentCategory[
                        parentCategory.key
                      ]?.length > 1 &&
                      categoriesList.childrenOfParentCategory[
                        parentCategory.key
                      ][0].key === END_OF_PRODUCT_TYPE_SEARCH;

                    return (
                      <div
                        key={`${topCategory.key}-${parentCategory.key}`}
                        className={classNames(
                          css.categoryContainer,
                          subCategorySelectedIndex === parentCategory.key
                            ? css.open
                            : null
                        )}
                      >
                        <div className={css.linkLabel}>
                          <NamedLink
                            key={parentCategory.key}
                            name="CategoryPageWithParent"
                            className={css.subCategoryLink}
                            params={{
                              topLevelCategory: topCategory.url_key,
                              parentCategory: parentCategory.url_key,
                            }}
                          >
                            {parentCategory.label}
                          </NamedLink>
                          {hasNestedCategoriesList && (
                            <InlineTextButton
                              className={css.arrowButton}
                              onClick={() =>
                                setSubCategorySelectedIndex(parentCategory.key)
                              }
                            >
                              <IconArrowHead
                                direction="right"
                                size="small"
                                className={css.icon}
                              />
                            </InlineTextButton>
                          )}
                        </div>
                        {hasNestedCategoriesList && (
                          <div
                            className={classNames(css.subSubCategoryContainer, {
                              [css.displayItem]:
                                subCategorySelectedIndex === parentCategory.key,
                            })}
                          >
                            {categoriesList.childrenOfParentCategory[
                              parentCategory.key
                            ]?.map(productType => {
                              return (
                                productType.key !==
                                  END_OF_PRODUCT_TYPE_SEARCH && (
                                  <div
                                    key={productType.key}
                                    className={css.linkLabel}
                                  >
                                    <NamedLink
                                      key={productType.key}
                                      name="CategoryPageWithProductType"
                                      className={css.subCategoryLink}
                                      params={{
                                        topLevelCategory: topCategory.url_key,
                                        parentCategory: parentCategory.url_key,
                                        productType: productType.url_key,
                                      }}
                                    >
                                      {productType.label}
                                    </NamedLink>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </nav>
    )
  );
};

CategoryBar.defaultProps = {
  rootClassName: null,
  className: null,
  categoriesList: [],
};

CategoryBar.propTypes = {
  rootClassName: string,
  className: string,
};

export default CategoryBar;
