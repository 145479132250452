import pick from 'lodash/pick';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryArtisanReviews, queryOwnListingByAuthorId } from '../../util/api';
import {
  denormalisedResponseEntities,
  getImageVariantInfo,
} from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createImageVariantConfig } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';

import { fetchCurrentUser } from '../../ducks/user.duck';
import { JH_LISTING_TYPES } from '../../util/types';
import { ARTISAN } from '../../config/constants/listing.constants';

const ITEM_PER_PAGE = 96;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/MakerListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/MakerListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/MakerListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/MakerListingPage/SHOW_LISTING_ERROR';

export const QUERY_LISTINGS_REQUEST =
  'app/MakerListingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS =
  'app/MakerListingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/MakerListingPage/QUERY_LISTINGS_ERROR';

export const FETCH_REVIEWS_REQUEST =
  'app/MakerListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS =
  'app/MakerListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/MakerListingPage/FETCH_REVIEWS_ERROR';

export const SET_ARTISAN_ID = 'app/MakerListingPage/SET_ARTISAN_ID';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  listings: [],
  listingsMeta: {},
  queryListingsError: null,
  queryListingsInProgress: false,

  reviews: [],
  fetchReviewsError: null,
  fetchReviewsInprogress: false,
  offset: null,
  artisanID: null,
};

const MakerListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        id: null,
        showListingError: null,
      };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        id: payload.listingId,
        showListingError: null,
      };
    case SHOW_LISTING_ERROR:
      return {
        ...state,
        id: null,
        showListingError: payload,
      };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsError: null,
        queryListingsInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: [...state.listings, ...payload.listings],
        queryListingsInProgress: false,
        listingsMeta: payload.meta,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingsError: payload,
        queryListingsInProgress: false,
      };
    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchReviewsInProgress: true,
        fetchReviewsError: null,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        fetchReviewsInProgress: false,
        reviews: [...state.reviews, ...payload.reviews],
        offset: payload.offset,
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        fetchReviewsInProgress: false,
        fetchReviewsError: payload,
      };
    case SET_ARTISAN_ID:
      return {
        ...state,
        artisanID: payload,
      };
    default:
      return state;
  }
};

export default MakerListingPageReducer;

// ================ Action creators ================ //

const requestAction = actionType => params => ({
  type: actionType,
  payload: { params },
});

const successAction = actionType => result => ({
  type: actionType,
  payload: result.data,
});

const errorAction = actionType => error => ({
  type: actionType,
  payload: error,
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST,
});
export const showListingSuccess = payload => ({
  type: SHOW_LISTING_SUCCESS,
  payload,
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = requestAction(QUERY_LISTINGS_REQUEST);
export const queryListingsSuccess = successAction(QUERY_LISTINGS_SUCCESS);
export const queryListingsError = errorAction(QUERY_LISTINGS_ERROR);

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = data => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: data,
});
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const setArtisanId = artisanID => ({
  type: SET_ARTISAN_ID,
  payload: artisanID,
});

// ================ Thunks ================ //

export const showListing = ({
  config,
  isOwn = false,
  makerListingId,
  currentUserId,
}) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest());
  const params = {
    id: makerListingId,
    include: ['images', 'author'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn
    ? queryOwnListingByAuthorId(currentUserId)
    : sdk.listings.show;

  return show(params)
    .then(response => {
      const [currentListing] = denormalisedResponseEntities(response);

      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(showListingSuccess({ listingId: currentListing.id.uuid }));
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      return currentListing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

const queryListings = ({ makerID, page = 1, config }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(queryListingsRequest({}));
  const imageVariantInfo = getImageVariantInfo(config.listing);
  const queryParams = {
    pub_makerID: +makerID,
    include: ['images', 'author'],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
    // 'limit.images': 1,
    page,
    per_page: ITEM_PER_PAGE,
    pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
  };

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      dispatch(
        queryListingsSuccess({
          data: {
            listings: entities,
            meta: response.data.meta,
          },
        })
      );
      return entities;
    })
    .catch(e => {
      dispatch(queryListingsError(e));
    });
};

export const loadMoreListings = params => dispatch => {
  return dispatch(queryListings(params));
};

export const fetchReviews = () => async (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  const { artisanID, offset } = getState().MakerListingPage;

  const params = {
    artisanID,
    offset,
    type: ARTISAN,
  };
  try {
    const response = await queryArtisanReviews(params);

    const {
      data: { reviews, authorsDataResponse },
      meta: { offset },
    } = response;

    if (authorsDataResponse) {
      dispatch(addMarketplaceEntities(authorsDataResponse));
    }

    dispatch(fetchReviewsSuccess({ reviews, offset }));
  } catch (error) {
    log.error(error, 'fetching-reviews-failed');
    dispatch(fetchReviewsError(storableError(error)));
  }
};

export const loadData = (params, search, config) => dispatch => {
  const { makerListingId } = params;

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  ];

  return dispatch(fetchCurrentUser())
    .then(res => {
      if (ownListingVariants.includes(params.variant)) {
        const authorId = res.id.uuid;
        return dispatch(
          showListing({
            config,
            isOwn: true,
            makerListingId,
            currentUserId: authorId,
          })
        );
      }

      return dispatch(showListing({ config, makerListingId }));
    })
    .then(currentListing => {
      const artisanID = currentListing?.id.uuid;

      if (artisanID) {
        dispatch(setArtisanId(artisanID));
        dispatch(fetchReviews());
      }

      return dispatch(
        queryListings({
          makerID: currentListing.attributes.publicData.artisanID,
          config,
        })
      );
    });
};
