import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import css from './CookieConsent.module.css';

const COOKIE_CONSENT_KEY = 'cookiesConsent';

const saveCookieConsent = () => {
  // Set cookieConsent to 1 and max-age to 1 year
  document.cookie = `${COOKIE_CONSENT_KEY}=1; path=/; max-age=31536000`;
};

const CookieConsent = props => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});
    if (cookies[COOKIE_CONSENT_KEY] !== '1') {
      setShow(true);
    }
  }, []);

  const isServer = typeof window === 'undefined';

  if (isServer || !show) {
    return null;
  }

  const onAcceptCookie = () => {
    saveCookieConsent();
    setShow(false);
  }

  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.message}>
        <FormattedMessage id="CookieConsent.message" />
      </div>
      <button className={css.continueBtn} onClick={() => onAcceptCookie()}>
        <FormattedMessage id="CookieConsent.continue" />
      </button>
    </div>
  );
};

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,
};

export default CookieConsent;
