import React from 'react';
import { Link } from 'react-router-dom';

import './ModuleType4.css';

import Module4Image1 from '../../assets/novica-images/modules/module4-image1.png';
import Module4Image2 from '../../assets/novica-images/modules/module4-image2.png';

const ModuleType4 = () => {
  return (
    <section className="about-module-type4">
      <div className="container">
        <div className="text">
          <div className="heading">
            <span className="intro">Village Council</span>
            <h5>
              Artisans are in the{' '}
              <span className="c-highlight3">driver’s seat</span>.
            </h5>
          </div>
          <div className="content">
            <p>
              An elected body of artists and cultural stakeholders from around
              the world, including the Smithsonian Folklife Festival, represents
              the interests of our seller community, safeguards our mission and
              shapes the experience of the Handmade.com marketplace.
            </p>
            <p>
              <Link
                to="/about-us/village-council/"
                className="button button-offwhite button-animate button-animate-highlight1"
              >
                Meet the Village Council
                <span className="icon material-symbols-outlined c-highlight1">
                  arrow_right_alt
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="images">
          <div className="image1">
            <img src={Module4Image1} className="m4-img1" />
          </div>
          <div className="image2">
            <img src={Module4Image2} className="m4-img2" />
          </div>
        </div>
        <div className="content-bottom">
          <p>
            An elected body of artists and cultural stakeholders from around the
            world, including the Smithsonian Folklife Festival, represents the
            interests of our seller community, safeguards our mission and shapes
            the experience of the Handmade.com marketplace.
          </p>
          <p>
            <Link
              to="/about-us/village-council/"
              className="button button-offwhite button-animate button-animate-highlight1"
            >
              Meet the Village Council
              <span className="icon material-symbols-outlined c-highlight1">
                arrow_right_alt
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ModuleType4;
