export const ADMIN = 'admin';
export const SUPER_ADMIN = 'superAdmin';
export const ADMIN_ROLES = {
  ADMIN,
  SUPER_ADMIN,
};

export const compareTypes = [
  { key: 'previousPeriod', label: 'Previous period' },
  { key: 'samePeriodLastYear', label: 'Same period last year' },
];