import defaultConfig from '../../config/configDefault';
import googleMapsConfig from './config';

export const validateAddress = (address, countryCode) => {
  if (!address || !countryCode) {
    return Promise.reject(new Error('Address and country code are required'));
  }
  
  const url = `${googleMapsConfig.baseURL}?key=${googleMapsConfig.apiKey}`;
  const body = {
    address: {
      addressLines: [`${address}`],
      regionCode: countryCode
    }
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Referer': defaultConfig.marketplaceRootURL,
    },
    body: JSON.stringify(body),
  }).then(res => {
    return res.json();
  });
}