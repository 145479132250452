import React from 'react';
import { Link } from 'react-router-dom';

import Module1Image1 from '../../assets/novica-images/modules/module1-image1.png';
import Module1Image2 from '../../assets/novica-images/modules/module1-image2.png';
import Module1Image3 from '../../assets/novica-images/modules/module1-image3.png';
import Module1Image4 from '../../assets/novica-images/modules/module1-image4.png';
import Module1Image5 from '../../assets/novica-images/modules/module1-image5.png';

import './ModuleType1.css';

const ModuleType1 = () => {
  return (
    <section className="about-module-type1">
      <div className="container">
        <div className="text">
          <div className="heading">
            <span className="intro">Our Story</span>
            <h5>Empowering artisans and delighting customers.</h5>
          </div>
          <div className="content">
            <p>
              Learn how our parent company, NOVICA, has earned millions of loyal
              customers since 1999 while creating legacy relationships with
              National Geographic, Smithsonian Folklife Festival, Kiva, the
              International Trade Centre (WTO and United Nations), and so many
              other others.
            </p>
            <p>
              <Link
                to="/about-us/our-story/"
                className="button button-offwhite button-animate button-animate-highlight1"
              >
                Discover Our Story
                <span className="icon material-symbols-outlined c-highlight1">
                  arrow_right_alt
                </span>
              </Link>
            </p>
          </div>
        </div>
        <div className="images">
          <div className="imagerow1">
            <div className="image1">
              <img src={Module1Image1} className="m1-img1" />
            </div>
            <div className="image2">
              <img src={Module1Image2} className="m1-img2" />
            </div>
          </div>
          <div className="imagerow2">
            <div className="image1">
              <img src={Module1Image3} className="m1-img3" />
            </div>
            <div className="image2">
              <img src={Module1Image4} className="m1-img4" />
            </div>
            <div className="image3">
              <img src={Module1Image5} className="m1-img5" />
            </div>
          </div>
        </div>
        <div className="content-bottom">
          <p>
            Learn how our parent company, NOVICA, has earned millions of loyal
            customers since 1999 while creating legacy relationships with
            National Geographic, Smithsonian Folklife Festival, Kiva, the
            International Trade Centre (WTO and United Nations), and so many
            other others.
          </p>
          <p>
            <Link
              to="/about-us/our-story/"
              className="button button-offwhite button-animate button-animate-highlight1"
            >
              Discover Our Story
              <span className="icon material-symbols-outlined c-highlight1">
                arrow_right_alt
              </span>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};
export default ModuleType1;
