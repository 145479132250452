import classNames from 'classnames';
import React from 'react';

import css from './IconUpload.module.css';

const IconUpload = (props) => {
  const { rootClassName, className } = props;
  const classes = classNames(css.root || rootClassName, className);

  return (
    <svg
      className={classes}
      viewBox="0 0 23 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0304 2.28609C11.8942 2.14364 11.7018 2.0625 11.5 2.0625C11.2981 2.0625 11.1057 2.14364 10.9695 2.28609L7.13617 6.29651C6.86832 6.57673 6.88868 7.0116 7.18164 7.26781C7.47461 7.52402 7.92923 7.50455 8.19709 7.22432L10.7812 4.52082V14.6667C10.7812 15.0463 11.103 15.3542 11.5 15.3542C11.8969 15.3542 12.2187 15.0463 12.2187 14.6667V4.52082L14.8029 7.22432C15.0707 7.50455 15.5253 7.52402 15.8183 7.26781C16.1113 7.0116 16.1316 6.57673 15.8637 6.29651L12.0304 2.28609Z"
        fill="white"
      />
      <path
        d="M3.59375 13.75C3.59375 13.3703 3.27196 13.0625 2.875 13.0625C2.47805 13.0625 2.15625 13.3703 2.15625 13.75V13.8003C2.15623 15.054 2.15621 16.0644 2.26792 16.8591C2.38389 17.6842 2.632 18.3789 3.20884 18.9306C3.78568 19.4825 4.51197 19.7198 5.37457 19.8307C6.20542 19.9375 7.26181 19.9375 8.57242 19.9375H14.4276C15.7382 19.9375 16.7946 19.9375 17.6255 19.8307C18.4881 19.7198 19.2143 19.4825 19.7912 18.9306C20.368 18.3789 20.6161 17.6842 20.7321 16.8591C20.8437 16.0644 20.8438 15.054 20.8438 13.8003V13.75C20.8438 13.3703 20.5219 13.0625 20.125 13.0625C19.7281 13.0625 19.4062 13.3703 19.4062 13.75C19.4062 15.0658 19.4047 15.9835 19.3074 16.6759C19.2129 17.3486 19.04 17.7047 18.7747 17.9584C18.5094 18.2121 18.1371 18.3775 17.4339 18.468C16.71 18.561 15.7506 18.5625 14.375 18.5625H8.625C7.2494 18.5625 6.28999 18.561 5.56612 18.468C4.86292 18.3775 4.49053 18.2121 4.2253 17.9584C3.96007 17.7047 3.78714 17.3486 3.6926 16.6759C3.59527 15.9835 3.59375 15.0658 3.59375 13.75Z"
        fill="white"
      />
    </svg>
  );
};

export default IconUpload;
