import React from 'react';
import _ from 'lodash';

const ListingZoomIcon = ({ images, openLargePicModal }) => {
  const buttonOnClick = e => {
    e.preventDefault();
    openLargePicModal();
  };

  return (
    <div className="itemhoveraction">
      {/* <ListingImageFullScreenGallery button={button} imageItems={imageItems} /> */}
      <button className="button button-tiny zoom-in" onClick={buttonOnClick}>
        <span className="material-symbols-outlined">zoom_in</span>
      </button>
    </div>
  );
};

export default ListingZoomIcon;
