import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setInitialValuesForShoppingCart } from '../containers/CartPage/CartPage.duck';

export const PrepareDataForApp = () => {
  const dispatch = useDispatch();
  const setInitialValuesForCart = () => dispatch(setInitialValuesForShoppingCart());

  useEffect(() => {
    setInitialValuesForCart();
  }, []);
  return null;
};