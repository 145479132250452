export const SHIPPING_SERVICE_KEY = {
  FEDEX: 'FedEx',
  USPS: 'USPS',
  DHL: 'DHL',
  UPS: 'UPS',
  UPS_MAIL_INNOVATIONS: 'UPS-Mail-Innovations',
  OTHER: 'other',
};

export const shippingServices = [
  { key: SHIPPING_SERVICE_KEY.FEDEX, label: 'FedEx', regex: /^.{12,}$/ },
  {
    key: SHIPPING_SERVICE_KEY.USPS,
    label: 'USPS',
    regex: /^.{22,}$/,
  },
  { key: SHIPPING_SERVICE_KEY.DHL, label: 'DHL eCommerce', regex: /^.{30,}$/ },
  {
    key: SHIPPING_SERVICE_KEY.UPS,
    label: 'UPS',
    regex: /^.{18,}$/,
  },
  {
    key: SHIPPING_SERVICE_KEY.UPS_MAIL_INNOVATIONS,
    label: 'UPS Mail Innovations',
    regex: /^.{26,}$/,
  },
  { key: SHIPPING_SERVICE_KEY.OTHER, label: 'Other service', regex: /^\S+$/ },
];

export const shippingTransitLevels = [
  { key: 'standard', label: 'Standard' },
  { key: 'expedited', label: 'Expedited (No PO Boxes)' },
  { key: 'express', label: 'Express' },
];

export const getShippingTransitLevelByKey = key => {
  return shippingTransitLevels.find(item => item.key === key);
}
