import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { abbreviateName } from '../../util/string';

import css from './AbbreviationAvatar.module.css';

const AbbreviationAvatar = props => {
  const { className, rootClassName, initialsClassName, displayName } = props;
  const classes = classNames(rootClassName || css.root, className);
  const abbreviatedName = abbreviateName(displayName);
  return (
    <div className={classes} title={displayName}>
      <span className={initialsClassName || css.initials}>
        {abbreviatedName}
      </span>
    </div>
  );
};

AbbreviationAvatar.defaultProps = {
  className: '',
  rootClassName: '',
  initialsClassName: '',
  displayName: '',
};

AbbreviationAvatar.propTypes = {
  className: string,
  rootClassName: string,
  initialsClassName: string,
  displayName: string,
};

export default AbbreviationAvatar;
