import React, { useState } from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import ModuleType1 from '../../components/FooterModules/ModuleType1';
import ModuleType2 from '../../components/FooterModules/ModuleType2';
import ModuleType3 from '../../components/FooterModules/ModuleType3';
import ModuleType4 from '../../components/FooterModules/ModuleType4';
import ModuleType5 from '../../components/FooterModules/ModuleType5';

import css from './FooterContainer.module.css';

const quoteSection = {
  sectionType: 'quote-bar',
  sectionId: 'quote-bar',
  quotemode: 'inline',
  footer_quotes: [
    {
      quote:
        'To be creative means to be in love with life. You can be creative only if you love life enough that you want to enhance its beauty, you want to bring a little more music to it, a little more poetry to it, a little more dance to it.',
      by: 'Osho',
    },
    {
      quote:
        'Passion is one great force that unleashes creativity, because if you&apos;re passionate about something, then you&apos;re more willing to take risks.',
      by: 'Yo-Yo Ma',
    },
    {
      quote:
        'The beauty of art is that it allows you to slow down, and for a moment, things that once seemed unfamiliar become precious to you.',
      by: 'Kehinde Wiley',
    },
    {
      quote:
        'Never doubt that a small group of thoughtful, committed citizens can change the world. Indeed, it is the only thing that ever has.',
      by: 'Margaret Mead',
    },
    {
      quote:
        'I strongly believe that art has the capacity to touch the spirit, engage, educate, and heal in ways that words alone cannot.',
      by: 'Carolyn Mazloomi',
    },
    {
      quote:
        'If art is to nourish the roots of our culture, society must set the artist free to follow his vision wherever it takes him.',
      by: 'John F. Kennedy',
    },
    {
      quote:
        'Everyone should see how complicated, how deeply troubled, and yet at the same time, beautiful and awesome the world can be.',
      by: 'Anthony Bourdain',
    },
    {
      quote:
        'El arte es la expresión del alma que desea ser escuchada. (Art is the expression of the soul that wants to be heard.)',
      by: 'Julio Cesares',
    },
    {
      quote:
        'The basic strength of the country is invested in people who are wonderfully creative, if they have the opportunity.',
      by: 'Joseph Wilson',
    },
    {
      quote:
        'Art should be something that liberates your soul, provokes the imagination and encourages people to go further.',
      by: 'Keith Haring',
    },
    {
      quote:
        'Education is the passport to the future, for tomorrow belongs to those who prepare for it today.',
      by: 'Malcolm X',
    },
    {
      quote:
        'When we create art, we have the capacity to leave a better world for those who come after us.',
      by: 'Verónica Castillo',
    },
    {
      quote:
        'Handicrafts belong to a world existing before the separation of the useful and the beautiful.',
      by: 'Octavio Paz',
    },
    {
      quote:
        'The aim of art is to represent not the outward appearance but their inward significance.',
      by: 'Aristotle',
    },
    {
      quote:
        'The aim of art is to represent not the outward appearance but their inward significance.',
      by: 'Aristotle',
    },
    {
      quote:
        'When we enlarge our view of the world, we deepen our understanding of our own lives.',
      by: 'Yo-Yo Ma',
    },
    {
      quote:
        'I believe art is utterly important. It is one of the things that could save us.',
      by: 'Mary Oliver',
    },
    {
      quote:
        'How could we ever let our crafts die? It’s what identifies us as a people.',
      by: 'María Florinda Coy',
    },
    {
      quote:
        'A nation’s culture resides in the hearts and in the soul of its people.',
      by: 'Mahatma Gandhi',
    },
    {
      quote:
        'Weaving is our culture, because it is what our ancestors have left us.',
      by: 'Timoteo Ccarita Sacaca',
    },
    {
      quote:
        'By understanding other people’s culture, it makes it so much easier to be able to live in peace and harmony.',
      by: 'Timoteo Ccarita Sacaca',
    },
    {
      quote:
        'Art is the expression of the profoundest thoughts in the simplest way.',
      by: 'Albert Einstein',
    },
    {
      quote:
        'Art, freedom and creativity will change society faster than politics.',
      by: 'Victor Pinchuk',
    },
    {
      quote:
        'Each time a woman stands up for herself, she stands up for all women.',
      by: 'Maya Angelou',
    },
    {
      quote:
        'You can’t use up creativity. The more you use, the more you have.',
      by: 'Maya Angelou',
    },
    {
      quote: 'Art does not reproduce the visible; rather, it makes visible.',
      by: 'Paul Klee',
    },
    {
      quote: 'If we are to preserve culture we must continue to create it.',
      by: 'Johan Huizinga',
    },
    {
      quote: 'If art doesn’t make us better, then what on earth is it for?',
      by: 'Alice Walker',
    },
    {
      quote: 'To be native to a place we must learn to speak its language.',
      by: 'Robin Wall Kimmerer',
    },
    {
      quote: 'When love and skill work together, expect a masterpiece.',
      by: 'John Ruskin',
    },
    {
      quote: 'Be passionate about something and lean to that strength.',
      by: 'Michelle Obama',
    },
    {
      quote: 'Art is the only way to run away without leaving home.',
      by: 'Twyla Tharp',
    },
    {
      quote: 'The artist has to be a guardian of the culture.',
      by: 'Robert Longo',
    },
    {
      quote: 'This world is but a canvas to our imagination.',
      by: 'Henry David Thoreau',
    },
    {
      quote: 'To be indigenous is to protect life on earth.',
      by: 'Robin Wall Kimmerer',
    },
    {
      quote: 'Art is not living, it is the use of living.',
      by: 'Audre Lorde',
    },
    {
      quote: 'The artist…is the voice of the people.',
      by: 'Alice Walker',
    },
    {
      quote: 'To be an artist is to believe in life.',
      by: 'Henry Moore',
    },
    {
      quote: 'Art speaks the soul of its culture.',
      by: 'Abby Willowroot',
    },
    {
      quote: 'Putting people and planet first',
      by: 'European Millennium Development Goals',
    },
    {
      quote: 'Life is short, art is long.',
      by: 'John Ringling',
    },
    {
      quote: 'Culture matters',
      by: 'Lonnie Bunch',
    },
  ],
};

const SectionFooter = loadable(
  () =>
    import(
      /* webpackChunkName: "SectionBuilder" */ '../PageBuilder/SectionBuilder/SectionFooter/SectionFooterNew'
    ),
  {
    fallback: <div>Loading Footer...</div>,
  }
);
const SectionQuoteBar = loadable(
  () =>
    import(
      /* webpackChunkName: "SectionBuilder" */ '../PageBuilder/SectionBuilder/SectionQuoteBar'
    ),
  {
    fallback: <div>Loading Quote Bar...</div>,
  }
);

const RandomFooterModuleComponent = () => {
  const componentArray = [
    ModuleType1,
    ModuleType2,
    ModuleType3,
    ModuleType4,
    ModuleType5,
  ];
  const [elemNum, setElemNum] = useState(
    Math.floor(Math.random() * componentArray.length)
  );
  const RandomComponent = componentArray[elemNum];
  return <RandomComponent />;
};

const FooterComponentSection = ({ location }) => {
  const { footer = {} } = useConfiguration();

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }
  let showRandomFooterModuleComponent = false;
  if (
    _.includes(['/', '/search'], location.pathname) ||
    /^\/c\//.test(location.pathname) ||
    /^\/p\//.test(location.pathname) ||
    /^\/store\//.test(location.pathname)
  ) {
    showRandomFooterModuleComponent = true;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: '',
    sectionType: 'footer',
  };

  const isSellersPage = location.pathname.includes('/sellers') ? true : false;

  return (
    <section className={css.wrapper}>
      {/*<SectionBuilder sections={footerSection} />
      <div className={css.currencySelectWrapper}>{currencySelectOptions}</div>*/}
      {showRandomFooterModuleComponent && <RandomFooterModuleComponent />}
      {!isSellersPage && <SectionQuoteBar {...quoteSection} />}
      <SectionFooter {...footerSection} isSellersPage={isSellersPage} />
    </section>
  );
};

const FooterComponent = compose(withRouter)(FooterComponentSection);

export default FooterComponent;
