import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import { fetchRecentOrders } from '../../containers/AdminDashboardPage/AdminDashboardPage.duck';
import { prepareRecentOrdersData } from '../../containers/AdminDashboardPage/AdminDashboardPage.helpers';
import { DEFAULT_CURRENCY } from '../../../../../util/types';

import css from './ProductSalesTableAdmin.module.css';

export const ROWS_PER_PAGE = 10;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
  {
    id: 'saleAmount',
    numeric: true,
    disablePadding: false,
    label: 'Total Quantity Sold',
  },
  {
    id: 'saleRevenue',
    numeric: true,
    disablePadding: false,
    label: 'Total Revenue',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ display: 'none' }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ProductSaleMUITable = props => {
  const { intl } = props;

  const {
    recentOrders,
    getRecentOrdersInProgress,
    getRecentOrdersError,
    isLastPageRecentOrders,
  } = useSelector(state => state.AdminDashboardPage);
  const dispatch = useDispatch();
  const preparedRecentOrders = prepareRecentOrdersData(recentOrders);
  const [tableData, setTableData] = useState(preparedRecentOrders);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('saleRevenue');

  useEffect(() => {
    setTableData(preparedRecentOrders);
  }, [recentOrders]);

  if (getRecentOrdersError) {
    return 'There was an error while fetching the data';
  }

  if (isEmpty(tableData)) {
    return 'There is no product sales to show';
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = stableSort(tableData, getComparator(order, orderBy));

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ maxWidth: '100%' }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const saleRevenueformatted = intl.formatNumber(
                  row.saleRevenue,
                  {
                    style: 'currency',
                    currency: DEFAULT_CURRENCY,
                    currencyDisplay: 'symbol',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                );
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.listingId}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      component="th"
                      id={row.listingId}
                      scope="row"
                      padding="normal"
                    >
                      <div className={css.productNameCell}>{row.title}</div>
                    </TableCell>
                    <TableCell align="right">{row.saleAmount}</TableCell>
                    <TableCell align="right">{saleRevenueformatted}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!isLastPageRecentOrders ? (
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(fetchRecentOrders());
          }}
          disabled={getRecentOrdersInProgress}
        >
          {getRecentOrdersInProgress ? 'Loading' : 'Show more'}
        </Button>
      ) : null}
    </Box>
  );
};

export default ProductSaleMUITable;
